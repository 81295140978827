.top-detail{
	.top-report{
		margin-top: 15px;
		margin-bottom: 15px;
		li{
			display: inline-block;
			padding-right: 15px;
			span{
				color: #fff;
				font-size: 12px;
				margin:0;
			}
			.star-vote{
				font-size: 14px;
			}
		}
	}
}
.detail-main{
    border-bottom: 1px solid #dddddd;
    .basic-info{
    	margin-bottom: 20px;
    }

    .basic-info-right{
		color: #333;
    	@media only screen and (min-width : 320px) {
			h3{
				margin-top: 10px;
			}
    	}
    	
    	@media only screen and (min-width : 768px) {
    		h3{
				margin-top: 0px;
			}
    	}
		h3{
			font-size: 30px;
			margin-bottom: 10px;
			color: #fff;
			small{
				text-decoration: line-through;
				font-size: 12px;
				font-weight: bold;
			}
		}
		hr{
			margin: 20px 0px;
		}
		.join-class{
			margin:0;
			li{
				vertical-align: middle;
				.btn-join{
					font-size: 20px;
				    margin-right: 30px;
				    padding: 7px 15px;
				}
			}
		}
		.report-class{
			li{
				font-size: 13px;
				line-height: 25px;
				a{
					&:hover,
					&:hover i{
						color:#00A888;
					}
					i{
						margin-right: 5px;
						color:#87a6bc;
						font-size: 11px;
					}
				}
			}

		}
		table{
			font-size:12px;
			tr td{
				vertical-align: top;
				p{
					color:#000;
					margin-bottom: 10px;
				}
				strong{
					color:#000;
				}
				i{
					font-size: 5px;
					vertical-align: middle;
					margin-right: 5px;
					color:#87a6bc;
				}
			}
			td.set{
				padding-right: 30px;
				min-width: 150px;
			}
		}

		.course-info-in{
			h4{
				font-size: 13px;
				font-weight: bold;
				color: #fff;
			}
		}
    }

	.basic-info-document{
		h1{
			color: #333;
		}
		.use-info{
			margin: 40px 0;
		}
		h3.price{
			color: #ffaa00;
			font-size: 35px;
			margin-bottom: 15px;
		}
		button{
			width: 210px;
			font-size: 25px;
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
}

.enterprise-info{
	h1{
		color: #254f5b;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 25px;
		line-height: 30px;
		margin-bottom: 15px;

		@media only screen and (min-width : 320px) {
			text-align: center;
		}

		@media only screen and (min-width : 768px) {
			text-align: left;
		}
	}

	.left-info{
		@media only screen and (min-width : 320px) {
			padding: 0;
		}

		@media only screen and (min-width : 768px) {
			padding: 0 15px;
		}
		.description{
			text-align: justify;

			@media only screen and (min-width : 320px) {
				padding: 0 15px;
			}

			@media only screen and (min-width : 768px) {
				padding: 0;
			}
		}
	}

	.hr-img-mobile{
		@media only screen and (min-width : 320px) {
			display: block;
		}

		@media only screen and (min-width : 768px) {
			display: none;
		}
	}

	.enterprise-image{

	}

	.description{

	}
	.logo{
		img{

			@media only screen and (min-width : 320px) {
				width: 170px;
				margin: 20px auto 10px auto;
			}

			@media only screen and (min-width : 768px) {
				width: 230px;
				margin: 0 auto;
			}
		}
	}
	.en_name{
		text-align: center;
		font-weight: bold;
		@media only screen and (min-width : 320px) {
			font-size: 18px;
		}

		@media only screen and (min-width : 768px) {
			font-size: 18px;
		}
	}
	.icon-info{
		margin-bottom: 5px;
		i{
			color: #f37335;
			width: 20px;
		}
	}
	.description{
		margin-top: 15px;
	}
	.attribute{
		overflow: hidden;
		margin-top: 20px;
		li{

			@media only screen and (min-width : 320px) {
				width: 100%;
				text-align: center;
				margin-bottom: 5px;
			}

			@media only screen and (min-width : 768px) {
				width: 50%;
				text-align: left;
				margin-bottom: 3px;
			}

			float: left;
			background: #e6e6e6;
			padding: 5px 10px;
			color: #254f5b;
			a{
				color: #254f5b;
			}
		}

		li.title{
			@media only screen and (min-width : 320px) {
				margin-bottom: 0px;
				padding-bottom: 0px;
				span{
					color: #f37435;
					font-weight: bold;
					font-size: 18px;
				}
			}

			@media only screen and (min-width : 768px) {
				margin-bottom: 3px;
				padding-bottom: 5px;
				span{
					color: #254f5b;
					font-weight: normal;
					font-size: 14px;
				}
			}
		}
	}
	.box-activities{
		background: #e6e6e6;
		border-top: 2px solid #1a344d;
		margin-top: 50px;

		h3{
			text-align: center;
			padding: 15px;
			color: #f86a00;
			@media only screen and (min-width : 320px) {
				padding-top: 0;
			}
			@media only screen and (min-width : 768px) {
				padding-top: 15px;
			}
		}
		.ronate{
			position: relative;
			.box{
				border: 2px solid #1b344d;
				border-radius: 10px;
				background-color: #fbfbfb;
				z-index: 1;
				width: 50px;
				height: 50px;
				margin: auto;
				-ms-transform: rotate(45deg);
				-ms-transform-origin: 100% 100%;
				transform: rotate(45deg);
				transform-origin: 116% 34%;
				display: block;
				@media only screen and (min-width : 320px) {
					float: none;
				}
				@media only screen and (min-width : 768px) {
					float: left;
					margin-left: 100px;
				}
			}
			img{
				position: absolute;
				top: -16px;
				font-size: 27px;
				@media only screen and (min-width : 320px) {
					left: 48%;
				}
				@media only screen and (min-width : 768px) {
					left: 10%;
				}
			}
		}
		.activities_item{
			li{

				float: left;
				color: #314f51;
				text-align: center;
				line-height: 30px;
				padding: 5px;
				&:last-child{
					border-right:0;
				}

				a{
					color: #314f51;
				}

				@media only screen and (min-width : 320px) {
					width: 100%;
					border-right: none;
					font-size: 20px;
					margin: 5px 0;
					&:last-child{
						margin-bottom: 20px;
					}
				}

				@media only screen and (min-width : 768px) {
					width: 33%;
					border-right: 1px solid #314f51;
					font-size: 25px;

					margin: 15px 0;
					&:last-child{

					}
				}
			}
		}
	}
}

.media-list-enterprise{

	@media only screen and (min-width : 320px) {
		margin-top: 20px;
	}
	@media only screen and (min-width : 768px) {
		margin-top: 50px;
	}

	.title{
		font-weight: bold;
		margin-bottom: 20px;
		color: #314f51;
	}

	.media-left{
		img{
			width: 100px;
			height: 60px;
		}
	}

	.media-body{
		.media-heading{
			margin: 0;
			text-transform: uppercase;
			color: #314f51;
			font-size: 16px;
			margin-bottom: 5px;
			a{
				color: #314f51;
				&:hover{
					color: #f58634;
				}
			}
		}
		.icon-primary{
			color: #f37435;
		}
	}

	.wrap-more{
		margin-top: 15px;
		text-align: right;
		.one-more{
			border-radius: 12px;
			padding: 3px 12px;
			color: #1b344d;
			border: 1px solid #1b344d;
			font-family: ExtraBoldItalic;
			font-size: 12px;
		}
	}

}



.use-info-primary{
	.u-name{
		font-size: 15px;
	    font-weight: bold;
	    line-height: 20px;
	    margin-top: 10px;

		span {
			font-size: 13px;
		}
		a{
			color:$brand-primary;
			&:hover{
				color:#333;
			}
		}
	}
	.social-medium{
		margin-top: 5px;
	}
}

.course-detail-info{
	border:1px solid #ddd;
}

.course-detail-all{
	
	@media only screen and (min-width : 320px) {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	@media only screen and (min-width : 768px) {
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

.lecture-list{
	section{
		background-color: #fff;
		min-height: 100px;
		@media only screen and (min-width : 320px) {
			padding:15px 15px;
		}
		@media only screen and (min-width : 480px) {
			padding:15px 30px;
		}
		
		h3.title{
			font-size: 20px;
			color:#333;
			margin-bottom: 15px;
		}

		h4.title{
			font-size: 15px;
			color:#333;
			font-weight: bold;
			margin:0;
			margin-bottom: 10px;
		}

		p{
			margin:0;
		}

		ul{
			padding:0;
			list-style-position: inside;
		}
		ul.s_last{
			margin-bottom: 20px;
		}

		.note{
			margin-top: 20px;
			p{
				font-size: 13px;
			}
		}
	}
}

.summary{
	p,span{
		font-size: 13px !important;
		line-height: 22px;
		margin:0;
	}
}

.course-relation{
	
	@media only screen and (min-width : 992px) {
		padding-left: 5px;

		.panel-body {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	
	.course-list{
		background:#fff;
		border:1px solid #ddd;

		h3{
			margin-bottom: 20px;
			font-size: 20px;
    		color: #333;
		}
	}
}

.tag-list{
	background:transparent;
	border:none;
	box-shadow: none;

	.tag-item{
		border:1px solid #ddd;
		color: #999999;
	    border-radius: 4px;
	    background: #fff;
	    padding: 5px 10px;
	    display: inline-block;
    	margin-bottom: 5px;
    	font-size: 11px;

	    &:hover{
	    	color:$brand-primary;
	    }
	}

	.panel-body{
		padding:0;

		h3{
			margin-bottom: 20px;
		}
	}
}

.social-btn-online{
	margin-top: 15px;
}

.studying-public{
	li{
		width:50%;
	}
}

.discount-link{
	input{
		padding: 5px 15px;
	}
}

.guid-step{
	margin: 15px 0px;
	li{
		display:inline-block;
		font-size: 20px;
		margin: 0px 15px;
		color: #ddd;
		text-align: center;
		vertical-align: top;
		i{
			font-size: 30px;
		}
	}
	.text-guid{
		color: $brand-primary;
	}
	.step{
		i{
			font-size: 40px;
			line-height: 50px;
		}
	}
}

.pop-up-share{
	p{
		margin:0;
	}
}

.copy-link{
	.addon-copy{
		cursor: pointer;
		&:hover{
			color: $brand-primary;
		}
	}
}

.ul-social{
	padding:0;
	
	li{
		display:inline-block;

		a{
			background: url(../img/common/share_all.png) no-repeat;
		    display: block;
		    width: 32px;
		    height: 32px;
		    margin: 0;
		    cursor: pointer;
		}
	}

	.s-facebook{
		a{
			background-position: 0 -288px;
		}
	}

	.s-google{
		a{
			background-position: 0 -1313px;
		}
	}

	.s-twitter{
		a{
			background-position: 0 -1005px;
		}
	}

	.s-tump{
		a{
			background-position: 0 -108px;
		}
	}

	.s-blog{
		a{
			background-position: 0 -1421px;
		}
	}
}