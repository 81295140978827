#footer{
	background-color: #132d37;
	bottom: 0;
	width: 100%;
	padding: 50px 0 20px 0;
	margin-top: 50px;

	.contact{
		h4{
			font-size: 15px;
			font-weight: bold;
			color: #eee;
		}

		input,button{
			border: 0;
			border-radius: 0;
		}

		input{
			box-shadow: 0;
			outline: none;
			height: 40px;
		}

		button{
			margin-left: 10px;
		}
	}

	.logo-list{
		margin-top: 15px;

		.logo-foot{
			background: url("../img/novaedu/logo-foot.png") no-repeat;
			width: 191px;
			height: 44px;
			background-size: cover;
			background-position: center;
			margin-right: 15px;
		}

		a.fa{
			font-size: 30px;
			color: #89969b;
		}

		a{
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
		}

		li{
			margin-bottom: 20px;
			p{
				display: inline-block;
				font-size: 16px;
				margin-right: 20px;
				color: #eee;

				i{
					margin-right: 5px;
					color: $brand-primary;
				}
			}
		}
	}

	.bg-foot-map{
		height: 242px;
		background: url("../img/novaedu/bg-foot.png") no-repeat;
	}

	.line{
		height:1px;
		background-color: #89969b;
		margin: 12px 0;
		display:block;
	}

	.navbar-foot{
		width: 100%;
		li{
			display: inline-block;
			a{
				color: #89969b;
				&:hover{
					background-color: transparent;
					color: #eee;
				}
			}
			&:first-child{
				a{
					padding-left: 0;
				}
			}
		}
	}

	.foot-address{
		color: #eee;
		margin-top: 30px;
	}
}

.maps-wrapper {
	position: relative;
	//padding-bottom: 90%; //(450/500*100% = 90%)
	padding-bottom: 48%; //(290/600*100% = 90%)
	height: 0;
	overflow: hidden;
}

.maps-wrapper iframe {
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
}