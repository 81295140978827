.course-grid-small{
	margin-top: 30px;
	margin-bottom: 30px;

	@media only screen and (min-width : 320px) {
		.sidebar-cat{
			h4,.menu-child,.col-fill{
				padding-left: 15px;
				padding-right: 15px;
			}
			h4{
				text-transform: uppercase;
			    font-size: 12px;
			    color: #333;
			    font-weight: bold;
			}
		}
	}


	@media only screen and (min-width : 768px) {
		.course-item{
			padding-left: 10px;
			padding-right: 10px; 
		}
		.thumbnail{
			margin-bottom: 20px;
			img.cou-pic{
				min-height: 130px;
			}
		}
		.sidebar-cat{
			h4,.menu-child,.col-fill{
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	@media only screen and (min-width : 992px) {
		.last{
			padding-right: 15px;
		}
		.sidebar-cat{
			h4,.menu-child,.col-fill{
				padding-left: 15px;
			}
		}
	}

	@media only screen and (max-width : 480px) {
		
		.col-fill{
			width:100%;
		}
	}
	
}
.menu-child{
	margin-bottom: 25px;
	margin-top: 15px;
}

.list-menu-child{
	li{
		margin-bottom: 5px;

		a{
			display: block;
			color: #02a1a5;
			font-size: 12px;
			font-weight: bold;
			line-height: 20px;
			&:hover,
			&:active{
				color:$brand-primary;
			}
		}
		a.active{
			color:$brand-primary;
		}
	}
}

.line-cat-title{
	background: #fefefe;
	padding: 7px 15px;
	border-radius: 4px;
	color: #7cc150;
	h3{
		color: #7cc150;
		text-transform: uppercase;
		line-height: 35px;

		.icon-cate-new{
			width: 35px;
			height: 35px;
			float: left;
			background-image: url(../img/e-guide/icon_cate.png);
			background-size: contain;
			background-repeat: no-repeat;
			margin-right: 15px;
		}
	}
}