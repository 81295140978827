* {
  box-sizing: border-box;
}
@font-face {
  font-family: Medium;
  src: url(../fonts/font_v1/Montserrat-Medium.ttf);
}
@font-face {
  font-family: ExtraBoldItalic;
  src: url(../fonts/font_v1/Montserrat-ExtraBoldItalic.ttf);
}
@font-face {
  font-family: Bold;
  src: url(../fonts/font_v1/Montserrat-ExtraBold.ttf);
}

body {
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: #000000;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

#header {
  width: 100%;
  position: relative;
}

#header .header_top, #header .header_mid, #header .header_bottom {
  width: 100%;
}

#header .header_top {
  height: 35px !important;
  background-color: #1b344d;
}

#header .header_top a{
  padding: 5px;
  color: #fff;
  line-height: 35px !important;
}
.dropdown .btn {
  height: 22px;
  margin-top: 7px;
  line-height: 0px;
  border-radius: 0px;
}


#header .header_mid {
  background-color: #fff;
}

#header .header_mid .mxh {
  padding: 10px;
}

#header .header_mid .container .contact_header, #header .header_mid .container .search {
  position: relative;
  top: 10px;
}
#header .header_mid .container .contact_header h3{
  font-family: Bold;
}
#header .header_mid .container .contact_header i{
  color: #f86a00;
  padding-right: 10px;
}

#header .header_mid .container .form  .search {
  padding: 0px !important;
  margin: 0px !important;
}

#header .header_mid .container .form  .login {
  margin: 0px !important;
}

#header .header_mid .container .form input {
  border: 1px solid #c0a087;
  padding: 5px;
  width: 90%;
  margin-bottom: 5px;
  background-color: #e6e6e6;
  outline: none;
}

#header .header_mid .container .form input:hover {
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  border: none;
  border-bottom: 1px solid #c0a087;
  background-color: #ffffff;
}

#header .header_mid .container .form:hover .icon{
  border: none;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}


#header .header_mid .container .form .icon {
  position: absolute;
  right: 0;
  padding: 8px;
  z-index: 1;
  color: #4f5b66;
}

#header .header_mid .container .form .login div{
  background-color: #f37435;
  padding: 7px;
  width: 98%;
  margin: auto;
  color: #fff;
  font-size: 12px;
  bottom: 15px;
}

#header .header_mid .container .form .login a{
  color: #fff;
}

#header .header_mid_menu{
  border-top: 1px solid #f7f9f8;
}


#header .header_mid_menu .navbar-default {
  border-bottom: none;
}

#header .header_mid_menu .navbar-default li{
  padding: 10px 3px 0;
  z-index: 10;
  a{
     padding: 10px 8px; 
  }
}

#header .header_mid_menu .navbar-default .container .navbar-toggle {
  margin-top: 16px;
}

#header .header_mid_menu .container .navbar-header .brand{
  padding: 10px 0 20px 19px;
  display: none;
}

#header .header_mid_menu .container #navbar .dropdown-menu li {
  padding: 0px;
  border-bottom: 1px solid #7c8b8a;
}

//dropdown button kich hoat the
.header_top .container .dropdown .dropdown-menu li {
  padding: 0px;
  border-bottom: 1px solid #7c8b8a;
  color: red;
}
.header_top .container .dropdown .dropdown-menu li a{
 padding:0px;
}
.header_top .container  li a:hover {
  background-color: aqua;
  color:#fff;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}


#header .header_mid_menu .container #navbar li:before {
  content: "";
  position: absolute;
  left: auto; /*change here*/
  right: 0;/*change here*/
  width: 0;
  bottom: 0px;
  height: 5px;
  background-color: #76bf4e;
  transition: 0.5s;
  z-index: 1;
}

#header .header_mid_menu .container #navbar li:after {
  content: "";
  position: absolute;
  right: auto; /*change here*/
  left: 0;/*change here*/
  width: 0;
  bottom: 0px;
  height: 5px;
  background-color: #f37435;
  transition: 0.5s;
  z-index: 1;
}

#header .header_mid_menu .container #navbar li a:hover {
  background-color: #f37435;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

#header .header_mid_menu .container #navbar a {
  font-family: Medium;
  color: #000;
  font-size: 13px;
}

#header .header_mid_menu .container #navbar a:nth-child(even) {
  position: relative;
  left: 10px;
}

#header .header_mid_menu .container #navbar a:hover {
  color: #ffffff;
}

#header .header_mid_menu .container #navbar span {
  position: absolute;
  z-index: 10;
  bottom: 18px;
  font-size: 15px;
}

.header_bottom {
  position: relative;
  bottom: 10px;
}

.header_bottom .title {
  font-family: Bold;
  font-weight: bold;
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  top: 20%;
  font-size: 50px;
}

.header_bottom .title .left{
  position: relative;
  left: 15%;
}

.header_bottom .title .left .left_title{
  padding: 20px;
}

.header_bottom .title .right{
  position: relative;
  bottom: 25px;
}


.header_bottom .title .left span:first-child {
  color: #1b344d;
}


.header_bottom .title .left .online {
  color: #f37435;
}

.header_bottom .title .left .des {
  font-size: 16px;
  position: relative;
  bottom: 40px;
  font-family: Bold;
  color: #1b344d;
}

.header_bottom .title .left .lienhe {
  font-size: 16px;
  width: 70%;
  padding-top: 15px;
  position: relative;
  bottom: 30px;
}

.header_bottom .title .left .lienhe a{
  color: #fff;
  border-radius: 12px;
  -webkit-box-shadow: 0px 7px 5px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 7px 5px -5px rgba(0,0,0,0.75);
  box-shadow: 0px 7px 5px -5px rgba(0,0,0,0.75);
  padding: 8px 15px;
  background-color: #f37435;
  font-family: Arial;
}

.header_bottom .title .left .lienhe a:hover{
  background-color: #fff;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  color: #000;
}

#body {
  margin-bottom: 40px;
}

#body .body_mid h1{
  font-family: Bold;
}

#body .body_mid .des h5{
  font-family: Bold;
  text-align: justify;
  line-height: 20px;
}

#body .body_mid .des ul li{
  font-weight: bold;
}

#body .body_mid .des .thamgia a{
  border-radius: 12px;
  padding: 8px 15px;
  float: right;
  color: #1b344d;
  border: 2px solid #1b344d;
  font-family: ExtraBoldItalic;
}

#body .body_mid .des .thamgia a:hover{
  background-color: #f37435;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  color: #fff;
}

#introduce .introduce .col {
  margin: 40px auto;
  color: #2e455c;
  background-color: #fff;
  width: 80%;
  padding: 1px;
  border-top: 2px solid #1b344d;
}

#introduce .introduce .col:hover {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-top: 2px solid #f37435;
}

#introduce .introduce .col:hover h4{
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #f37435;
}
#introduce .introduce .col:hover .khu {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 2px solid #f37435;
}
#introduce .introduce .col:hover i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #f37435;
}

#introduce .introduce .col:hover .button {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #f37435;
  border: 1px solid #f37435;
}

#introduce .introduce .col p{
  width: 80%;
  height: 160px;
  font-weight: bold;
  margin: auto;
  text-align: justify;
}

#introduce .introduce .col .khu{
  border: 2px solid #1b344d;
  width: 25%;
  border-radius: 10px;
  background-color: #fbfbfb;
  z-index: 1;
  width: 50px;
  height: 50px;
  margin: auto;
  -ms-transform: rotate(45deg); /* IE 9 */
  -ms-transform-origin: 100% 100%; /* IE 9 */
  transform: rotate(45deg);
  transform-origin:  116% 34%;
}

#introduce .introduce .col h4{
  height: 40px;
  margin-top: 0px;
  font-family: Bold;
  color: #f37435;
}

#introduce .introduce .col .ronate{
  position: relative;
}

#introduce .introduce .col .ronate .bong{
  opacity: 0.4;
}

#introduce .introduce .col .ronate i{
  position: absolute;
  top: -15px;
  right: 43%;
  font-size: 27px;
}

#introduce .introduce .col .intro{
  margin: 20px;
}

#introduce .introduce .col .intro a{
  border-radius: 12px;
  padding: 8px 15px;
  color: #1b344d;
  border: 1px solid #1b344d;
  font-family: ExtraBoldItalic;
}

#journeys {
  position: relative;
  top: 55px;
}

#journeys span{
  color: #f37435;
}

#journeys .row h1{
  color: #1b344d;
  margin: auto;
  width: 80%;
  text-align: center;
  font-family: Bold;
}

#journeys .row .logotitle{
  margin: auto;
  width: 80%;
}

#journeys .row p{
  text-align: justify;
  font-size: 14px;
  line-height: 29px;
  word-spacing: 3px;
  height: 400px;
  font-weight: bold;
  width: 90%;
  overflow-y: scroll;
  margin: auto;
  color: #1b344d;
}

#journeys .row p::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

#journeys .row p span{
  color: #f37435;
}

#journeys .row p span{
  color: #f37435;
}

#journeys .row div{
  padding-left: 0px;
  margin-left: 0px;
  padding-right: 0px;
  margin-right: 0px;
}

#journeys .row .images{
  padding-bottom: 35px;
}

#journeys .row .images .img{
  overflow: hidden;
  height: 175px;
  position: relative;
}

#journeys .row .images .img:hover img{
  transform: scale(1.2);
  -webkit-transition:0.6s;
  -o-transition:0.6s;
  transition:0.6s;
}

#journeys .row .images .img i{
  position: absolute;
  top: 50%;
  font-size: 32px;
  color: white;
  display: none;
  left: 50%;
  right: 0px;
}

#journeys .row .images:hover i{
  display: block;
}

#journeys .row .images .text{
  text-align: center;
  background-color: #336799;
  font-weight: bold;
  font-family: Medium;
  color: #fff;
  width: 98.5%;
  height: 60px;
  margin: auto;
  font-size: 16px;
  padding: 10px;
}

#process{
  position: relative;
  top: 50px;
  background-color: #fbfbfb;
}

#process .process{
  text-align: center;
  position: relative;
  top: 50px;
  color: #1b344d;
}

#process .process h1{
  font-family: Bold;
  padding: 5px;
}

#process .process img{
  width: 40%;
  margin: auto;
}


#process .col {
  margin: 80px auto 30px;
  color: #2e455c;
  background-color: #fff;
  width: 80%;
  padding: 1px;
  border-radius: 14px;
}

#process .col:hover .khu {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 2px solid #f37435;
}
#process .col:hover h4 {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #f37435;
}

#process .col:hover .button {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #f37435;
  border: 1px solid #f37435;
}

#process .col p{
  width: 88%;
  font-weight: bold;
  margin: auto;
  height: 210px;
  text-align: justify;
}

#process .col p::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

#process .col .khu{
  border: 2px solid #1b344d;
  width: 25%;
  border-radius: 10px;
  background-color: #fbfbfb;
  z-index: 1;
  width: 50px;
  height: 50px;
  margin: auto;
  -ms-transform: rotate(45deg); /* IE 9 */
  -ms-transform-origin: 100% 100%; /* IE 9 */
  transform: rotate(45deg);
  transform-origin:  116% 34%;
}

#process .col img{
  width: 95%;
  margin: auto;
}


#process .col h4{
  font-family: Bold;
  padding: 10px;
  color: #f37435;
}

#process .col .intro{
  margin: 20px;
}

#process .col .intro a{
  border-radius: 12px;
  padding: 8px 15px;
  color: #1b344d;
  border: 1px solid #1b344d;
  font-family: ExtraBoldItalic;
}

#footer {
  color: #ffffff;
  background-image: url('footer.png');
  background-size: 100% 100%;
}

#footer h4 {
  text-align: left;
  font-weight: bold;
}

#footer .novagroup {
  width: 75%;
  text-align: justify;
  margin: auto;
}

#footer .novagroup .mxh2 img{
  padding-left: 25px;
}

#footer .contact p i{
  font-size: 18px;
  padding-right: 15px;
}

#footer .contact form input{
  margin: 5px;
}

#footer .contact form button{
  background-color: rgba(0,0,0,0);
  font-size: 16px;
  font-weight: bold;
  margin: 5px;
  padding: 4px 25px;
  border: 2px solid #ffffff;
}

#footer .contact form button:hover{
  background-color: #f37435;
  -webkit-transition:0.6s;
  -o-transition:0.6s;
  transition:0.6s;
  border: 2px solid #1b344d;
}

#footer .site {
  padding: 15px 0px 0px 15px;
}

#footer .site p{
  color: #f37435;
}

#footer .site p a{
  color: #ffffff;
}

#footer .site p a:hover{
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  color: #f37435;
}

#copyright {
  background-color: #122d36;
  color: #ffffff;
  padding: 10px;
  position: relative;
  font-family: Medium;
}

#copyright .left {
  width: 30%;
  left: 0px;
  position: absolute;
  bottom: 0px;
  z-index: 99;
  border-bottom: 1px solid #ffffff;
}

#copyright .right {
  width: 30%;
  right: 0px;
  position: absolute;
  bottom: 0px;
  z-index: 99;
  border-bottom: 1px solid #ffffff;
}
@media (max-width: 520px) {
  h1, .h1{
    font-size: 23px;
  }
  #introduce .introduce .col h4{
    height: auto;
  }
  #journeys, #process, #process .process {
    position: relative;
    top: 20px;
  }
  #journeys .row p{
    width: 72%;
    height: 330px;
    margin-bottom: 10px;
  }
  #journeys .row .images{
    width: 72%;
    margin: 0 auto;
  }
  #journeys .row .images .text{
    font-size: 13px;
    height: auto;
  }
  #process .col{
    margin: 40px auto 10px;
  }
  #process .col p{
    height: auto;
  }
  #header .header_mid .container .form .login div{
    background: none;
  }
  #header .header_mid .container .form .login a{
    color:#f37435
  }
}
@media (max-width: 320px) {
  .header_bottom .title .left .des {
    font-size: 13px;
  }
  #introduce .introduce .col h4{
    height: auto;
  }
  #header .header_mid_menu .container #navbar span {
    display: none;
  }
  h1, .h1{
    font-size: 19px;
  }
  #journeys, #process {
    position: relative;
    top: 20px;
  }
  #journeys .row p{
    width: 72%;
    height: 330px;
    margin-bottom: 10px;
  }
  #journeys .row .images{
    width: 72%;
    margin: 0 auto;
  }

}

@media (max-width: 766px) {
  .navbar-nav .dropdown-menu .caret { transform: rotate(-90deg);}
  .contact_header {
    text-align: center;
  }
  .navbar-default {
    border-bottom: #EBEBEB;
  }

  .navbar-toggle {
    background-color: #f37435 !important;
  }

  .navbar-default .navbar-toggle .icon-bar {
    background-color: #77c050 !important;
  }

  .navbar-nav .open .dropdown-menu>li>a {
    line-height: 40px !important;
  }

  #header .header_mid_menu .container #navbar span {
    display: none;
  }

  #header .header_mid_menu .container .navbar-default .logo2{
    display: block;
  }

  .navbar-default {
    border-bottom: #EBEBEB;
  }

  .header_bottom .title {
    position: relative;
  }

  .header_bottom .title .left{
    text-align: center;
    left: 0px;
    width: 100%;
    margin: auto;
  }

  .header_bottom .title .left .lienhe {
    width: 100%;
    text-align: center;
    margin: auto;
  }

  #body .body_mid img{
    width: 100%;
  }

  #body .body_mid .des .thamgia a {
    float: none;
  }

  #body .body_mid .des .thamgia {
    text-align: center;
    padding-top: 10px;
  }

  #introduce .introduce .col .intro a{
    bottom: 2%;
  }

  #journeys {
    margin: 0px;
  }

  #process .process img{
    width: 90%;
  }

  #footer {
    background-size: auto;
  }

  #footer .site {
    width: 100%;
    display: flex;
  }

  #footer .site div{
    width: 50%;
    padding-left: 0px;
    margin: auto;
  }

  #footer .contact form input{
    margin: 0px;
    margin-bottom: 5px;
  }

  .navbar .navbar-collapse .nav > li > a:before {
    display: none;
  }

  #header .header_mid_menu .container .navbar-header .brand{
    background-color: #1b344d;
    display: block;
  }

  #header .header_mid_menu .container #navbar .nav li {
    border-bottom: 1px solid #7c8b8a;
  }

  #introduce .introduce .col p {
    overflow: scroll;
  }

  #process .col {
    width: 100%;
  }

}

@media (max-width: 1024px) {
  .header_bottom .title {
    font-size: 35px;
  }

  .header_bottom .title .left .des {
    font-size: 16px;
    position: relative;
    z-index: 1;
    bottom: 30px;
    font-family: Bold;
    color: #1b344d;
  }
}

#footer .contact input{
  height: 34px;
}

/* about home css */
.home-about {
  background: #F1F1F1;
  padding: 25px 0px;
}
.home-about .col-md-8{
  border-right: dashed #033557;
  padding-left: 0px;
  padding-right: 10px;
}
.home-about .col-md-8 h3{
 text-shadow: -4px 0px #fff;
 color: #f37435;
 font-size: 31px;
 font-weight: bold;
}
.home-about .col-md-4 span:nth-child(1){
  font-size: 77px;
  text-shadow: -3px 0px #fff;
  color: #f37435;
  font-weight: bold;
}
.home-about .col-md-4{
   margin-top: -21px;
   padding-left: 10px;
}
.home-about .col-md-4 span:nth-child(2){
 font-size: 29px;
 text-shadow: 4px 0px #fff;
 color: #f37435;
 font-weight: bold;
}
.home-about .orientation{
 border: 1px solid #033557;
 padding: 0px ;
 border-radius: 5px;
 margin-bottom: 7px;
}
.home-about .orientation .col-md-1{
 font-size: 23px;
 color: #fff;
 padding: 7px ;
 background: #33527E;
 text-align: center;
 border-top-left-radius: 5px;
 border-bottom-left-radius: 5px;
}
.home-about .orientation .col-md-11{
 font-size: 16px;
}
.home-about .orientation .col-md-11 p{
 margin: 0 0 0px;
 color: #033557; 
}
.home-about .orientation .col-md-11 .p_2, .p_3, .p_5{
   padding-top: 10px ;
}
.home-about .intro a {
 border-radius: 12px;
 padding: 6px 12px;
 color: #1b344d;
 border: 1px solid #1b344d;
 font-family: ExtraBoldItalic;
}
.home-about .p1 ,.p3{
 font-size: 21px;
 color: #033557;
 margin: 0 0 0px;
}
.home-about .p2{
 font-size: 21px;
 color: #033557;
 font-family: ExtraBoldItalic;
 font-weight: bold;
 font-size: 19px;
 margin: 0 0 0px;
}

.home-about  .col-md-12{
 padding-left: 0px;
 padding-right: 0px;
}
.about-youtube{
  margin-top: 20px ; 
  padding: 0px 130px;
  iframe{
    height: 420px;
  }
}
// liên hệ
.con-contact{
    .row p{
      color: #E9501D;
      font-weight: bold;
      font-size: 30px;
      font-family: Bold;
      margin-bottom: 30px ;
    }
    .col-contact{
         background: #FBC279;
         border-radius: 15px;
         padding-bottom: 10px;
         padding-left: 0px;
         padding-right: 0px;
         .col-12{
             background: #F39200;
             border-radius: 15px;
             padding-top: 10px;
             margin-top: -10px;
             margin-left: -10px;
             form{
              margin-top: 15px;
             }
         }
         .col-p{
           margin: 0 0 0px;
           text-align: center;
           font-size: 19px;
         }
         .col-s{
           text-align: center;
           margin: 0 0 0px;
           font-size: 19px;
           font-weight: bold;
           color: #fff;
           padding: 5px 0px;
         }
    }
}

.carousel-showmanymoveone {
  .col-xs-12{
     text-align: center;
     .show-modal-review{
        border:none ;
        background: inherit;
        img{
          width: 200px;
          height: 200px;
          border-radius: 50%;
        }
     }
     .p-name{
        margin: 0 0 0px ;
        font-weight: bold;
        font-size: 20px;
        padding-top: 5px;
     }
     .p-title{
        font-style: italic;
        color: #FFBC09;
        font-size: 17px;
     }
     .p-desct{
       font-size: 14px;
       text-align: justify;
     }
    }
    .carousel-control i{
      color: #efefef;
    }
   
}
.modal-show{
  .name{
      color: #001868;
      font-family: UTMAvo_Bold;
      font-size: 23px;
      font-weight: bold;
      margin-top: 0px;
  }
  .titles{
    color: #FFBC09;
    font-style: italic;
    font-size: 17px;
  }
  .description{
    font-size: 19px;
  }
  .content{
    font-size: 19px;
  }
}

.sroll-student{
  overflow-x: hidden;
  height: 400px;
  .content img{
       width: 100% !important;
       height: auto !important;
  }
}
.modal-body-s {
  padding-top: 5px;
  .img{ 
    margin-top: 22px;
  }
  .description{
    color: #0f1f58;
    line-height: 21px;
    margin-top: 10px;
    text-align: justify;
  }
}

.carousel-showmanymoveone
{
  .carousel-control
  { 
    width: 4%;
    background-image:none;

    &.left 
    {
      margin-left:15px;
    }

    &.right 
    {
      margin-right:15px;
    }
  }

  .cloneditem-1, 
  .cloneditem-2, 
  .cloneditem-3
  {
    display: none;
  }

  .carousel-inner
  {
    @media all and (min-width: 768px)
    {
      @media (transform-3d), (-webkit-transform-3d)
      {
        > .item.active.right,
        > .item.next
        { 
          transform: translate3d(50%, 0, 0);  
          left: 0;
        }

        > .item.active.left,
        > .item.prev
        { 
          transform: translate3d(-50%, 0, 0);
          left: 0;
        }

        > .item.left,
        > .item.prev.right,
        > .item.active
        {
          transform: translate3d(0, 0, 0);
          left: 0;
        }    
      } 

      > .active.left,
      > .prev
      {
        left: -50%;
      }

      > .active.right,
      > .next
      {
        left:  50%;
      }

      > .left,
      > .prev.right,
      > .active
      {
        left: 0;
      }

      .cloneditem-1 
      {
        display: block;
      }
    }

    @media all and (min-width: 992px)
    {    
      @media (transform-3d), (-webkit-transform-3d)
      {
        > .item.active.right,
        > .item.next
        { 
          transform: translate3d(25%, 0, 0);  
          left: 0;
        }    

        > .item.active.left,
        > .item.prev
        { 
          transform: translate3d(-25%, 0, 0);
          left: 0;
        }

        > .item.left,
        > .item.prev.right,
        > .item.active
        {
          transform: translate3d(0, 0, 0);
          left: 0;
        }
      }

      > .active.left,
      > .prev
      {
        left: -25%;
      }

      > .active.right,
      > .next
      {
        left:  25%;
      }

      > .left,
      > .prev.right,
      > .active
      {
        left: 0;
      }

      .cloneditem-2, 
      .cloneditem-3
      {
        display: block;
      }
    }    
  }
}

.carousel-show-rulest
{
  .carousel-control
  { 
    width: 4%;
    background-image:none;

    &.left 
    {
      margin-left:15px;
    }

    &.right 
    {
      margin-right:15px;
    }
  }

  .cloneditem-1, 
  .cloneditem-2, 
  .cloneditem-3
  {
    display: none;
  }

  .carousel-inner
  {
    @media all and (min-width: 768px)
    {
      @media (transform-3d), (-webkit-transform-3d)
      {
        > .item.active.right,
        > .item.next
        { 
          transform: translate3d(50%, 0, 0);  
          left: 0;
        }

        > .item.active.left,
        > .item.prev
        { 
          transform: translate3d(-50%, 0, 0);
          left: 0;
        }

        > .item.left,
        > .item.prev.right,
        > .item.active
        {
          transform: translate3d(0, 0, 0);
          left: 0;
        }    
      } 

      > .active.left,
      > .prev
      {
        left: -50%;
      }

      > .active.right,
      > .next
      {
        left:  50%;
      }

      > .left,
      > .prev.right,
      > .active
      {
        left: 0;
      }

      .cloneditem-1 
      {
        display: block;
      }
    }

    @media all and (min-width: 992px)
    {    
      @media (transform-3d), (-webkit-transform-3d)
      {
        > .item.active.right,
        > .item.next
        { 
          transform: translate3d(25%, 0, 0);  
          left: 0;
        }    

        > .item.active.left,
        > .item.prev
        { 
          transform: translate3d(-25%, 0, 0);
          left: 0;
        }

        > .item.left,
        > .item.prev.right,
        > .item.active
        {
          transform: translate3d(0, 0, 0);
          left: 0;
        }
      }

      > .active.left,
      > .prev
      {
        left: -25%;
      }

      > .active.right,
      > .next
      {
        left:  25%;
      }

      > .left,
      > .prev.right,
      > .active
      {
        left: 0;
      }

      .cloneditem-2, 
      .cloneditem-3
      {
        display: block;
      }
    }    
  }
}
// end liên hệ
@media (max-width: 520px) { 
  .home-about .col-md-8 {
    padding-left: 15px;
    padding-right: 15px;
    border-right: none
  }
  .home-about .col-md-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .home-about .orientation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .about-youtube{
    padding: 0px 0px;
    iframe{
      height: 240px;
    }
  }
  .home-about .orientation .col-md-11 .p_2, .p_3, .p_5 {
    padding-top: 0px ;
  }
  .home-about .orientation .col-md-11 {
    padding: 10px ;
  }
  .home-about .col-md-8 h3 {
    font-size: 23px;
  }
  .home-about .col-md-4 span:nth-child(1) {
    font-size: 35px;
  }
  .home-about .col-md-4 span:nth-child(2) {
    font-size: 23px;
  }
  .home-about .col-md-4 {
    margin-top: -7px;
  }
  .home-about .p1, .p3 {
    font-size: 18px;
  }
  .home-about .p2 {
    font-size: 16px;
  }
  .home-about .orientation .col-md-1 {
    text-align: center;
  }
  .home-about .col-md-6 img{
     width: 70% !important;
  }
  .con-contact .row p {
    margin-bottom: 0px;
    margin-top: 15px;
  }
  .con-contact .col-left{ 
     margin-top: -20px;
  }
  .col-contact>.col-md-12{ 
    margin-top: 15px;
  }
  .col-contact{
    padding-left: 15px !important;
  }
  .con-contact .col-contact {
    background: none;
    margin-top: 20px;
  }
  .con-contact .row p {
    font-size: 22px ;
  }
  .con-contact .col-contact .col-p {
    font-size: 14px;
  }
  .header-row p {
    font-size: 23px;
  }
  .header-row {
    margin-top: 10px;
    margin-bottom: 5px;
}
.sroll-student{
  overflow-x: hidden;
  height: 400px;
}
}
//detail
.gallery_item{

  .image_link{
    margin-bottom: 25px;
    .tab-img{
      height: 70px;
    }
    div{
      height: 210px;
      overflow: hidden;
    }
    img{
      width: 100%;
      // height: 85px;
    }

    .gallery_content{
      text-align: center;
      bottom: 0;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: -ms-flex;
      display: flex;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      -ms-justify-content: center;
      justify-content: center;
      -webkit-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      position: absolute;
      top: 60px;
      opacity: 0;
      visibility: hidden;
      right: 0;
      left: 0;
      text-align: center;
      transition: all 0.3s ease-in-out;
      z-index: 2;
    }

    &:hover{
      .gallery_content{
        top: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .ripple {
    margin: 0;
    background-color: #FFFFFF;
    border-radius: 100%;
    color: #FFBC09;
    position: relative;
    display: inline-block;
    line-height: 50px;
    font-size: 18px !important;
    vertical-align: middle;
    margin-left: 0;
    height: 50px;
    width: 50px;
    text-align: center;
    margin: 0 auto;
  
    i{
      line-height: 50px;
    }
  }
  .home-ripple{
    top: -70px;
  }

  .ripple::before,.ripple::after {
    -webkit-animation: ripple 1.8s infinite ;
    -moz-animation: ripple 1.8s infinite ;
    -o-animation: ripple 1.8s infinite ;
    -ms-transition:ripple 1.8s infinite ;
    animation: ripple 1.8s infinite ;
    background-color: #ffffff;
    border-radius: 100%;
    margin: -20px;
    bottom: 0px;
    content: "";
    display: block;
    left: 0px;
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index:-1;
  }
  .ripple::before {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
  }
  .ripple::after {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
  }
}

@-webkit-keyframes ripple{
  0%{
    opacity:0;
    -webkit-transform:scale(0);
    transform:scale(0)
  }
  50%{
    opacity:0.5;
  }
  100%{
    opacity:0;
    -webkit-transform:scale(1);
    transform:scale(1)
  }
}
@keyframes ripple{
  0%{
    opacity:0;
    -webkit-transform:scale(0);
    transform:scale(0)
  }
  50%{
    opacity:0.5;
  }
  100%{
    opacity:0;
    -webkit-transform:scale(1);
    transform:scale(1)
  }
}

