/********************
* Mobile
*******************/
.sidebar{
    float: left;
    height: 100%;
    overflow-y: auto;
}
.navbar-unibee{
    width: 100%;
}
#wrapper{
    transition: all 0.5s ease;
    position: relative;
}
.navbar-outline{
    width:100%;
}
.effect-animate,
.effect-right{
    height: 100%;
    overflow: hidden;
    position: relative;
}

#page-content-wrapper{
    position: absolute;
    height: 100%;
    width: 100%;
    transition: .6s;
    top: 0;
    left: 0;
    min-height: 100%;
    padding-top: 50px;
}
#home{
    #page-content-wrapper{
        padding-top: 0px;
    }
}

#sidebar-left{
    position:absolute;
    background: #eee;
}

.effect-right{
    #sidebar-right{
        right:0;
        background: #fff;
    }
}

@media only screen and (min-width : 320px) {
    .effect-animate{
        #page-content-wrapper{
            transform: translateX(250px);
        }
    }
    .sidebar{
        width:250px;
    }
    #sidebar-right{
        right:-250px;
    }
}
@media only screen and (min-width : 480px) {
    .effect-animate{
        #page-content-wrapper{
            transform: translateX(480px);
        }
    }
    .sidebar{
        width:480px;
    }
    #sidebar-right{
        right:-480px;
    }
}

.sidebar-menu{
    > li{
        position: relative;
        line-height: 20px;
        display: inline-block;
        width: 100%;
        a:before{
            background-repeat: no-repeat;
            background-size: 100px;
            background-image: url('img/');
        }
        a{
            display: block;
            color: #1a344d;
            font-weight: bold;
            text-decoration: none;
            padding: 10px 15px 10px 15px;
            white-space: normal;
             .iconsub{
                 float: right;
                 line-height: 38px;
             }
            .novaicon{ color: #ff7905}
        }
    }
    li .active{
        color: #f58634;
        background: transparent;
    }
    li.child > a:after{
        margin-right: 0;
    }

    .cat-parent{
        padding-left: 15px;
    }

    .dropdown-header{
        padding: 3px 15px;
        text-transform: uppercase;
        font-size: 11px;
    }

    .btn-menu-action{
        a{
            margin: 7px 15px;
            border-radius: 5px;
            text-align: center;
            padding: 7px 0;
            color:#fff;
        }
        a.btn-test{
            color: #01a64f;
            border: 1px solid #01a64f;
            &:hover{
                background: #01a64f;
                color: #fff;
            }
        }
    }
}

.sidebar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
}

#sidebar-right{
    position: fixed;
    top: 50px;
    z-index: 100;
    animation-duration: .6s;
    transition: .6s;
}