.bg-cover{
	height: 500px;
	@media only screen and (min-width : 320px) {
		background-size: cover;
	}

	@media only screen and (min-width : 768px) {
		background-size: contain;
	}

	background-position: center;
}

.box-demo-test{
	background: #fff url("../img/novaedu/bg-index.png") no-repeat;
	padding: 120px 0 30px 0;
	margin-top: -100px;
	h3{
		font-size: 30px;
		margin-bottom: 20px;
	}
	.btn-test{
		min-width: 220px;
	}
}

.box-home-tag{
	.label-cus{
		padding: 7px 14px;
		border-radius: 30px;
		background-color: #fafafa;
		color: #999999;
		border: 1px solid #ddd;
		text-transform: uppercase;
		display: inline-block;
		margin-bottom: 10px;
		&:hover{
			color: #fff;
			background-color: $brand-primary;
		}
	}
	.active{
		color: #fff;
		background-color: $brand-primary;
	}
}

.btn-search-course{
	padding: 12px 18px 10px 18px !important;
}

.btn-default-user{
	padding: 10px 18px 2px 18px  !important;
}

.btn-view-more{
	background: #fff;
	border: 1px solid #ffaa00;
	color: #ffaa00;
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 20px;
	width: 290px;
	margin: 0 auto;
	&:hover{
		color: #333;
	}
}

.doc-big-title{
	margin-top: 70px;
}

.doc-sub-title{
	font-size: 20px;
	color: #666666;
	margin-bottom: 30px;
	font-weight: bold;
}

.ul-chose-login{
	li{
		display: inline-block;
		p{
			font-size: 24px;
			color: #696969;
			margin-top: 10px;
		}
	}
}

.login-require{
	width: 155px;
	height: 155px;
	display: inline-block;
	margin: 0 20px;
}
.login-home{
	background: url("../img/novaedu/login_main.jpg") no-repeat;
	width: 154px;
	background-size: cover;
}
.login-st{
	background: url("../img/novaedu/login_st.png") no-repeat;
	width: 154px;
}
.login-sts{
	background: url("../img/novaedu/login_sts.png") no-repeat;
}
.login-work{
	background: url("../img/novaedu/login_work.png") no-repeat;
	width: 156px;
}
.sub-title-login{
	font-size: 20px;
	text-transform: uppercase;
	color: #999999;
}

.block-active{
	position: relative;
	background-image: url('../img/common/education.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-attachment: fixed;
	border-width: 0;
	width: 100%;
	height: 600px;
}

.color-overlay{
	width: 100%;
	height: 100%;
	position: absolute;
	margin: 0 auto;
	background-color: #0f131c;
	opacity: 0.8;
	//opacity: 0.92771084337349;
}

.container-active{

}

.active-form{
	padding: 60px 0;

	h1,h2,h3,p{
		color: #fff;
	}

	h1{
		margin: 10px 0;
		text-transform: uppercase;
		@media only screen and (min-width : 320px) {
			font-size: 18px;
		}
		@media only screen and (min-width : 480px) {
			font-size: 30px;
		}
		@media only screen and (min-width : 768px) {
			font-size: 36px;
		}
	}

	h3{
		margin: 10px 0;
		@media only screen and (min-width : 320px) {
			font-size: 14px;
		}
		@media only screen and (min-width : 480px) {
			font-size: 18px;
		}
		@media only screen and (min-width : 768px) {
			font-size: 24px;
		}
	}

	form{
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.cod-field{
		@media only screen and (min-width : 320px) {
			margin-right: 15px;
			margin-left: 15px;
		}

		@media only screen and (min-width : 768px) {
			background: #fff;
			border-radius: 3px;
			padding: 8px;
			margin-left: 0;
			margin-right: 0;
		}

		input{
			border: 0;
			font-size: 20px;
			text-transform: uppercase;
			color: #999;
			height: 52px;

			@media only screen and (min-width : 320px) {
				margin-bottom: 15px;
			}

			@media only screen and (min-width : 768px) {
				margin-bottom: 0;
			}
		}

		button{
			font-size: 20px;
			font-weight: bold;
			min-width: 215px;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
			&:hover{
				background: #ee3c33;
			}

			@media only screen and (min-width : 320px) {
				width: 100%;
			}

			@media only screen and (min-width : 768px) {
				width: auto;
			}
		}

	}
}

.success-form{
	padding: 60px 0;

	h1,h2,h3,p{
		color: #fff;
	}

	.cropped{
		font-size: 50px;
	}

	.text-ok{
		font-size: 35px;
	}

	.redirect{
		a{
			&:hover{
				text-decoration: underline;
			}
		}
	}
}

.square_teaser.vertical-item {
	margin: 0 auto;
	//max-width: 360px;
}

.vertical-item .item-media + .item-content {
	padding-top: 30px;
}

.vertical-item .list1 {
	margin: 0;
	text-align: left;
}

.vertical-item .list1 + .social-icons {
	margin-top: 30px;
}

.vertical-item.content-padding .item-content {
	padding: 29px 0 34px;
}

.vertical-item.content-padding .item-content.entry-content {
	padding: 20px 0 23px;
}

.vertical-item.content-padding .item-content.entry-content:before,
.vertical-item.content-padding .item-content.entry-content:after {
	clear: both;
	content: "";
	display: block;
}

.vertical-item.content-padding .item-content .read-more,
.vertical-item.content-padding .item-content .item-title + a,
.vertical-item.content-padding .item-content .item-title {
	text-transform: uppercase;
}

.vertical-item.content-padding .item-content .item-title {
	margin-bottom: 0.6em;
}

.vertical-item.content-padding .item-content .read-more {
	display: inline-block;
	font-weight: 600;
	letter-spacing: 1px;
	margin-top: 1.5em;
	position: relative;
}

.vertical-item.content-padding .item-content .read-more:hover {
	color: #3d3d47;
}

.vertical-item.content-padding .item-content .read-more:after {
	content: "\f137";
	font-family: Flaticon;
	margin-left: 0.5em;
}

.vertical-item.content-padding .item-content .item-title + a {
	color: #b7b7b7;
	display: inline-block;
	margin-bottom: 1.15em;
}

.vertical-item.content-padding .item-content .item-title + a:hover {
	color: #29b5ee;
}

.vertical-item.content-padding.sticky .item-content {
	position: relative;
}

.vertical-item.content-padding.sticky .item-content.entry-content {
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 27px 23px 23px;
}

.vertical-item.content-padding.sticky .item-content:after,
.vertical-item.content-padding.sticky .item-content:before {
	content: "";
	position: absolute;
	right: 0;
	top: 0;
}

.vertical-item.content-padding.sticky .item-content:after {
	border-color: transparent #29b5ee transparent transparent;
	border-style: solid;
	border-width: 0 50px 50px 0;
	height: 0;
	width: 0;
	z-index: 1;
}

.vertical-item.content-padding.sticky .item-content:before {
	color: #fff;
	content: "\f148";
	font-family: Flaticon;
	font-size: 16px;
	right: 5px;
	top: 2px;
	z-index: 2;
}

.vertical-item.content-absolute {
	position: relative;
}

.vertical-item.content-absolute .item-content {
	bottom: 0;
	left: 0;
	padding: 0 30px 20px;
	position: absolute;
	right: 0;
}

@media only screen and (max-width: 399px) {
	.vertical-item.content-absolute .item-content {
		margin-top: 10px;
		padding: 0 20px 20px;
	}

	.vertical-item.content-absolute .item-content ol.custom_list {
		line-height: 2.25em;
	}
}

.vertical-item.content-absolute.vertical-center .item-content {
	line-height: 1.1;
	top: 0;
}

.vertical-item + .item-title {
	padding: 10px 30px;
}

.vertical-item + .item-title h2,
.vertical-item + .item-title h3,
.vertical-item + .item-title h4 {
	margin: 0;
}

/* item meta */
.item-meta {
	margin-bottom: 10px;
}

.item-meta i {
	font-size: 20px;
	margin: 0;
	padding: 0;
	position: relative;
	top: 3px;
}

/* item media links */
.item-media {
	overflow: hidden;
	position: relative;
}

.item-media img {
	border-radius: 3px;
	width: 100%;
}

.item-media-wrap {
	position: relative;
	text-align: center;
}

@media only screen and (max-width: 991px) {
	.square_teaser.vertical-item {
		//max-width: 346px;
	}
}

.square_teaser .item-content {
	margin: 14px 0 0;
	overflow: hidden;
	top: 0;
}

@media (min-width: 992px) and (max-width: 1160px) {
	.square_teaser .item-content {
		margin: 7px 0 0;
	}
}

.square_teaser:not(.vertical-item) .item-content {
	padding-left: 38px !important;
	padding-right: 38px !important;
}

@media (min-width: 992px) and (max-width: 1160px) {
	.square_teaser:not(.vertical-item) .item-content {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}

.square_teaser .teaser_icon {
	bottom: 30px;
	color: #29b5ee;
	left: 30px;
	position: absolute;
}

.square_teaser .item-media,
.square_teaser .item-media img {
	border-radius: 3px;
}

.square_teaser .item-media img,
.square_teaser .item-media:after,
.square_teaser .teaser_icon,
.square_teaser h4,
.square_teaser p {
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

@media (min-width: 992px) and (max-width: 1160px) {
	.square_teaser p + .custom_list {
		margin-top: -1em;
	}
}

.square_teaser h4 {
	color: #fff;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 30px;
	margin-bottom: 0.7em;
	margin-top: 21px;
	max-width: 85%;
	position: relative;
}

@media (max-width: 1200px) {
	.square_teaser h4 {
		font-size: 1.5em;
		max-width: 90%;
	}
}

@media (min-width: 992px) and (max-width: 1160px) {
	.square_teaser h4 {
		margin-bottom: 0.3em;
	}
}

@media (max-width: 767px) {
	.square_teaser h4 {
		margin-top: 10px;
	}
}

.square_teaser h4 > span {
	color: #fff;
	font: 700 50px/1em "Oswald";
	left: 0;
	opacity: 0.2;
	position: absolute;
	top: -0.3em;
}

.square_teaser h5 {
	color: #fff;
	margin-bottom: 1.55em;
}

@media (max-width: 1200px) {
	.square_teaser h5 {
		margin-bottom: 1em;
	}
}

.square_teaser ol.custom_list li:before {
	color: #fff;
}

.square_teaser .item-content {
	color: #fff;
}

.square_teaser:hover .item-media img {
	-webkit-transform: scale(1.05, 1.05);
	-ms-transform: scale(1.05, 1.05);
	transform: scale(1.05, 1.05);
}

@media only screen and (max-width: 767px) {
	.square_teaser:hover .item-media img {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}
}

.square_teaser.no-image .item-content {
	margin: 0;
}

.square_teaser.no-image .teaser_icon {
	position: static;
}

.square_teaser.no-image p {
	opacity: 1;
}

.square_teaser .social-icons a {
	border-radius: 5em;
	color: #fff;
	height: 30px;
	line-height: 30px;
	width: 30px;
}

.square_teaser .social-icons a:before {
	font-size: 15px;
}

.square_teaser .social-icons a:hover {
	background-color: #fff;
	color: #29b5ee !important;
}

.square_teaser:not(.vertical-item) .social-icons a.social-icon + a.social-icon {
	margin-left: 18px;
}

.mt_minus{
	margin-top: -2.9285em;
}

.nova-what [class*="col-"]{
	margin-bottom: 15px;
	margin-top: 15px;
}

@media only screen and (max-width: 1023px) {
	.mt_minus {
		margin-top: -25px;
	}
}

/* start */
.gallery_item-program .image_link img {
	height: auto !important;
  }
 
 

//  end itro