.exam-big-title{

  color: #7cc150;
  text-transform: uppercase;
  position: relative;
  font-weight: bold;

  @media only screen and (min-width : 320px) {
    font-size: 20px;
    text-align: center;
    margin: 25px 0 20px 0;
  }

  @media only screen and (min-width : 768px) {
    font-size: 30px;
    text-align: center;
    margin: 50px 0 20px 0;
  }

  b{
    color: #f37935;
  }
}

.start_exam{
  margin-bottom: 20px;
  @media only screen and (min-width : 320px) {
    text-align: center;
  }

  @media only screen and (min-width : 768px) {
    text-align: left;
  }
}

.exam-icon-top{
  background: url("../img/e-guide/exam_icon_top.png");
  width: 65px;
  height: 85px;
  background-size: cover;
  margin: 0;
  position: absolute;
  right: 20%;
  top: -32px;
  z-index: 80;
}

.exam-title{
  background: #f7a171;
  padding: 10px 30px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.tab-exam-info{
  .exam-info{
    tr{
      td{
        p{
          font-size: 17px;
          color: #333;
        }
      }
    }
    .ex-info-name{
      color: #5d8396;
      padding-left: 20px;
    }

  }

  .start-exam{
    padding-left: 30px;
  }
}

.welcome-exam-done{
  .wow-done-big,
  .wow-done-sub{
    text-transform: uppercase;
    font-weight: bold;
  }

  .wow-done-big{
    font-size: 50px;
    color: #f37935;
    line-height: 40px;
    @media only screen and (max-width : 480px) {
      font-size: 25px;
    }
  }

  .wow-done-sub{
    font-size: 22px;
    line-height: 30px;
    color: #000;
  }

  .title-done,
  .result-done{
    text-align: center;
    padding: 5px 0;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    float: left;
  }

  .title-done{
    width: 70%;
    background: #7cc150;
  }

  .result-done{
    width: 30%;
    background: #f37935;
  }

  .top-head{
    margin-top: 30px;
  }

  .bottom-head{
    margin-top: 67px;
  }
}

.exam-question-info{
  .questions{

    @media only screen and (min-width : 320px) {
      padding-left: 0px;
    }

    @media only screen and (min-width : 768px) {
      padding-left: 50px;
    }

    h3{
      font-size: 20px;
      margin-bottom: 10px;
    }
    li.question{
      display: list-item;
    }
    .question{

    }

  }

  .answers{
    margin: 20px 0 0 0;
    li {
      margin-bottom: 5px;
      label{
        font-style: normal;
        font-size: 15px;
        font-weight: normal;
      }
    }
    .tool-option{
      margin-top: 20px;

      a.nextQuestion{
        margin-right: 10px;
      }
      .skipQuestion{
        color: #5d8396;
      }

      .mess_error,.last_question{
        margin-top: 10px;
        font-style: italic;
        display: none;
      }

      .mess_error{
        color: #B5121B;
      }

      .last_question{
        color: #3c763d;
      }

    }
  }

  .box-item-question{
    border-radius: 0 0 20px 0;
    .box-time{
      color: #fff;
      position: relative;
      padding-top: 50px;

      .icon-time{
        width: 60px;
        height: 55px;
        background: url("../img/e-guide/exam_icon_clock.png") no-repeat;
        float: left;
        margin-right: 10px;
        background-size: cover;
        position: absolute;
        top: 15px;
        left: 10%;
      }

      .box-wrap-time{
        background: #e6e7e8;
        color: #333;
        margin: 0 15px;
        border-radius: 3px;
      }
    }

    .text-time{
      p{
        margin: 0;
      }
      .sub-text-time{
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        padding: 5px 45px;
      }
      .count-time{
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        font-weight: bold;
      }
    }
  }

  .box-list-question{
    .list-question{
      border-top: 0;
      padding: 9px;
      padding-top: 15px;
      .item-circle-question{
        width: 35px;
        height: 35px;
        background: #e6e7e8;
        border-radius: 100%;
        display: inline-block;
        color: #000;
        margin: 5px;
        text-align: center;
        line-height: 35px;
        cursor: pointer;
        margin-bottom: 10px;
      }

      .active{
        background: #7cc150;
        color: #fff;
      }
    }
  }

}

.sw-theme-arrows>ul.step-anchor>li.active>a:after{
  border-left: 30px solid #f37935!important;
}

.sw-theme-arrows>ul.step-anchor>li.active>a{
  border-color: #f37935!important;
  color: #fff !important;
  background: #f37935 !important;
  text-transform: uppercase;
}

.sw-theme-arrows{
  border: none;
  margin-bottom: 110px;
}

.sw-theme-arrows>ul.step-anchor{
  border-radius: 3px;
}

.sw-theme-arrows .step-content{
  padding: 0;
  margin-top: 15px;
}

.tab-content{
  border: none;
}

.sw-theme-arrows>ul.step-anchor{
  background: none;
  border-radius: 0;
  border: none;
}

.sw-theme-arrows>ul.step-anchor>li>a:before{
  margin-left: 12px;
}

.sw-theme-arrows>ul.step-anchor>li>a{
  background: #7cc150;
  color: #fff;
}
.sw-theme-arrows>ul.step-anchor>li>a:hover:after{

}
.sw-theme-arrows>ul.step-anchor>li>a:before{
  border-left: 30px solid #fff;
}
.sw-theme-arrows>ul.step-anchor>li>a:after{
  border-left: 30px solid #7cc150;
}

.nav-step-exam{
  li{
    @media only screen and (min-width : 320px) {
      width: 100%;
    }
    @media only screen and (min-width : 768px) {
      width: 25%;
    }
  }
}

.exam-info{
  tr{
    td{
      @media only screen and (min-width : 320px) {
        width: 50%;
      }
      @media only screen and (min-width : 768px) {
        width: 20%;
      }
    }
  }
}

.sw-theme-arrows>ul.step-anchor>li:first-child>a{
  padding-left: 70px;
}

.sw-theme-arrows>ul.step-anchor>li>a:hover{
  background: #7cc150;
  border-color: #7cc150;
}
.sw-theme-arrows>ul.step-anchor>li>a:hover:after{
  border-left-color: #7cc150;
}

.sw-theme-arrows>ul.step-anchor>li.done>a:hover:after{
  border-left-color: #7cc150;
}

.sw-theme-arrows>ul.step-anchor>li>a,
.sw-theme-arrows>ul.step-anchor>li>a:hover{
  padding: 15px 0 15px 70px;
  text-transform: uppercase;
  color: #fff;
}

.wrap_main{
  background: #fff;
  margin-top: 30px;
  border-radius: 4px;
  margin-bottom: 110px;
}

.content-dimension{
  h3{
    font-size: 14px;
    margin: 30px 0 15px 0;
  }
  img, iframe{
    max-width: 100% !important;
  }
}
.suggest-item{
  min-width: 200px;
  min-height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(60deg, #26c6da, #00acc1);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4);
}
.suggest-item:hover{
  color: #fff;
}
.kqbaikt{
  padding: 0;
  list-style: none;
}
.kqbaikt .panel-title{
  background: #d0f7b7;
  padding: 10px;
  font-weight: bold;
  color: #2c2b2b;
}
.kqbaikt .content{
  padding: 20px;
}
.question i {
  color: #ff7a00;
}
.answers i {
  color: #7cc150;
}
.start-exam,
.load_result{
  padding: 5px 20px;
}

.tips_exam_chain{
  font-size: 16px;
  color: #4145a8;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin: 20px 0;
  b{
    color: #ff9900;
  }
}

.btn-action-chain{
  background: #ff9900;
  text-transform: uppercase;
}

.chain-process-title{
  background: #7cc150;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #fff;
  margin: 20px 0px;
  border-radius: 0;
  b{
    color: #140e05;
  }
}


.chain-process-heading{
  background: #326699;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #fff;
  margin: 20px 10px;
  border-radius: 0;
  b{
    color: #ff9900;
  }
}

.chain-process-wrap{
  position: relative;
  text-align: center;
}

.chain-block{
  width: 35px;
  height: 35px;
  background: #43a047;
  position: absolute;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  font-size: 25px;
}

.block_default{
  top: 218px;
  left: 278px;
}

.block_career{
  top: 110px;
  left: 390px;
}

.block_famous{
  top: 112px;
  right: 215px;
}

.block_init{
  top: 218px;
  right: 110px;
}

.block_course{
  top: 390px;
  right: 110px;
}

.block_business{
  bottom: 95px;
  right: 215px;
}

.block_trend{
  bottom: 91px;
  right: 382px;
}

.chain-title-recommend{
  background: #7cc150;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  margin: 50px 0 0;
}

.view-more-chain{
  text-align: center;
  color: #fff;
  font-size: 14px;
  background: #7cc150;
  padding: 5px 20px;
  border-radius: 2px;
}

#body .body_heading {
  text-align: center;
  padding: 10px;
}
#body .body_heading h1 {
  font-family: Bold;
  color: #1b344d;
}
#body .body_content .left h4 {
  font-weight: bold;
}

.active, .accordion:hover {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: rgba(0,0,0,0);
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #ff9900;
  font-size: 23px !important;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: rgba(0,0,0,0);
}

.nav > li > a:hover, .nav > li > a:focus {
  background-color: rgba(0,0,0,0);
}

a:hover, a:focus {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  color: none;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  padding: 5px;
}

.radio label, .checkbox label {
  padding-left: 0px;
}

.diem {
  position: relative;
  top: 10px;
}

.checkmark{
  position:absolute;
  top:0;
  left:0;
  width:82px;
  z-index: -1;
  border: 1px solid #1b344d;
  height:45px;
  background-color: #fff;
}
.checkmark :hover{
  background-color: #ff9900;
}
.checkmark:after{
  content:"";
  position:absolute;
  display:none;
}
.container input:checked ~ .checkmark{
  background: #ff9900;
}

.container input:checked ~ .diem{
  color: #ffffff;
}

.container input:checked ~ .checkmark:after{
  display:block;
}

#header {
  width: 100%;
  position: relative;
}

#header .header_top, #header .header_mid, #header .header_bottom {
  width: 100%;
}

#header .header_top {
  height: 30px;
  background-color: #1b344d;
}

#header .header_top a{
  padding: 5px;
  color: #fff;
  line-height: 30px;
}

#header .header_mid {
  background-color: #fff;
}

#header .header_mid .mxh {
  padding: 10px;
}

#header .header_mid .container .contact_header, #header .header_mid .container .search {
  position: relative;
  top: 18px;
}
#header .header_mid .container .contact_header h3{
  font-family: Bold;
}
#header .header_mid .container .contact_header i{
  color: #f86a00;
  padding-right: 10px;
}

#header .header_mid .container .form  .search {
  padding: 0px !important;
  margin: 0px !important;
}

#header .header_mid .container .form  .login {
  margin: 0px !important;
}

#header .header_mid .container .form input {
  border: 1px solid #c0a087;
  padding: 5px;
  width: 90%;
  margin-bottom: 5px;
  background-color: #e6e6e6;
  outline: none;
}

#header .header_mid .container .form input:hover {
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  border: none;
  border-bottom: 1px solid #c0a087;
  background-color: #ffffff;
}

#header .header_mid .container .form:hover .icon{
  border: none;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}


#header .header_mid .container .form .icon {
  position: absolute;
  right: 5%;
  padding: 5px;
  border: 1px solid #c0a087;
  z-index: 1;
  color: #4f5b66;
}

#header .header_mid .container .form .login div{
  background-color: #f37435;
  padding: 7px;
  width: 98%;
  margin: auto;
  color: #fff;
  font-size: 12px;
  bottom: 15px;
}

#header .header_mid .container .form .login a{
  color: #fff;
}

#header .header_mid_menu{
  border-top: 1px solid #f7f9f8;
}

#header .header_mid_menu .navbar{
  margin-bottom: 0px;
}

#header .header_mid_menu .navbar-default {
  border-bottom: none;
}

#header .header_mid_menu .navbar-default li{
  padding: 10px 3px 0px;
  z-index: 9999999;
}

#header .header_mid_menu .navbar-default .container .navbar-toggle {
  margin-top: 15px;
}

#header .header_mid_menu .container .navbar-header .brand{
  padding: 15px;
  display: none;
}

#header .header_mid_menu .container #navbar .dropdown-menu li {
  padding: 0px;
  border-bottom: 1px solid #7c8b8a;
}

#header .header_mid_menu .container #navbar li:before {
  content: "";
  position: absolute;
  left: auto; /*change here*/
  right: 0;/*change here*/
  width: 0;
  bottom: 0px;
  height: 5px;
  background-color: #76bf4e;
  transition: 0.5s;
  z-index: 1;
}

#header .header_mid_menu .container #navbar li:after {
  content: "";
  position: absolute;
  right: auto; /*change here*/
  left: 0;/*change here*/
  width: 0;
  bottom: 0px;
  height: 5px;
  background-color: #f37435;
  transition: 0.5s;
  z-index: 1;
}

#header .header_mid_menu .container #navbar li a:hover {
  background-color: #f37435;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

#header .header_mid_menu .container #navbar a {
  font-family: Medium;
  color: #000;
}

#header .header_mid_menu .container #navbar a:nth-child(even) {
  position: relative;
  left: 10px;
}

#header .header_mid_menu .container #navbar a:hover {
  color: #ffffff;
}

#header .header_mid_menu .container #navbar span {
  position: absolute;
  z-index: 99999999;
  bottom: 18px;
  font-size: 15px;
}

#body .body_heading{
  text-align: center;
  padding: 10px;
}

#body .body_heading h1{
  font-family: Bold;
  color: #1b344d;
}

#body .body_content .left .start{
  color: #1b344d;
  font-family: Medium;
  font-weight: bold;
  padding: 10px 30px;
}

#body .body_content .left h4{
  font-weight: bold;
}

#body .body_content .left .start button{
  background-color: rgba(0,0,0,0);
  border: none;
  outline: none;
  border: 1px solid #1b344d;
  padding: 5px 25px;
  border-radius: 10px;
}

#body .body_content .left .start button:hover{
  background-color: #ff9900;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}


#body .body_content .nav-pills #pills-viewtest-tab:before{
  content: '📖';
  padding:15px;
}

#body .body_content .nav-pills a:not(#pills-viewtest-tab):before{
  content: '|';
  padding-right: 25px;
}

#body .body_content .nav-pills a{
  font-family: Bold;
  font-size: 18px;
}

#body .body_content #pills-do .questions{
  color: #1b344d;
  font-family: Medium;
}

#body .body_content #pills-do .checkbox{
  padding: 30px;
}

#body .body_content #pills-do .checkbox .answers{
  width: 70%;
}

#body .body_content #pills-do .checkbox .luuy{
  font-size: 16px;
}

#body .body_content #pills-do .checkbox .check{
  padding-top: 10px;
  padding-bottom: 50px;
}

#body .body_content #pills-do .checkbox .nextQuestion, #body .body_content #pills-do .checkbox .prevQuestion{
  border: 1px solid #1b344d;
  padding: 6px 12px;
  border-radius: 10px;
}

#body .body_content #pills-do .checkbox .nextQuestion:hover, #body .body_content #pills-do .checkbox .prevQuestion:hover{
  background-color: #f37435;
  color: #ffff;
}

#body .body_content #pills-ketqua .ketqua_heading {
  padding: 20px;
  font-family: Bold;
  color: #1b344d;
}

#body .body_content #pills-ketqua .ketqua_heading h1{
  font-size: 43px;
  color: #f37435;
  font-family: Bold;
}

#body .body_content #pills-ketqua .ketqua_heading p{
  font-size: 23px;
}

#body .body_content #pills-ketqua .ketqua_heading span{
  font-family: Medium;
  font-size: 20px;
}

#body .body_content #pills-ketqua .ketqua_button {
  padding: 20px;
  font-family: Medium;
}

#body .body_content #pills-ketqua .ketqua_button a{
  border: 1px solid #1b344d;
  padding: 6px 12px;
  border-radius: 10px;
}

#body .body_content #pills-ketqua .ketqua_button a:hover {
  background-color: #f37435;
  color: #ffff;
}

#body .body_content #pills-ketqua .character span{
  background-color: #1b344d;
  padding: 6px 23px;
  color: #ffff;
}

#body .body_content #pills-ketqua .iframe {
  width: 75%;
  margin: auto;
  padding: 20px;
}

#body .body_content #pills-ketqua .text {
  width: 98%;
  color: #1b344d;
  margin: auto;
}

#body .body_content #pills-ketqua .img {
  padding: 20px 0px 20px 0px;
  border-radius: 10px;
}

#body .body_content #pills-ketqua .save_eguide {
  padding-bottom: 20px;
}

#body .body_content #pills-ketqua .save_eguide a{
  padding: 7px 14px;
  border-radius: 10px;
  border: 1px solid #1b344d;
  font-family: Medium;
}

#body .body_content #pills-ketqua .save_eguide a:hover{
  background-color: #f37435;
  color: #fff;
}


#body .body_content #pills-ketqua .character .persionality{
  margin-left: 20px;
}

#body .body_content #pills-do h4 {
  font-family: Bold;
}

#body .body_content .box-time{
  text-align: center;
  color: #1b344d;
  position: relative;
}

#body .body_content .box-time .text-time {
  margin-top: 10px;
  background-color: #e6e7e8;
}

#body .body_content .box-time .text-time .sub-text-time {
  margin: 0px;
  font-family: Bold;
}

#body .body_content .box-time .text-time span{
  font-size: 23px;
  font-family: Medium;
}

#body .body_content .box-list-question .ask {
  display: inline-table;
  width: 35px;
  padding: 0px 10px 10px 0px;
  position: relative;
  height: 35px;
}

#body .body_content .box-list-question .ask .khung {
  border: 1px solid #1b344d;
  border-radius: 10px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -ms-transform-origin: 100% 100%; /* IE 9 */
  transform: rotate(45deg);
  transform-origin:  50% 100%;
}

#body .body_content .box-list-question .ask a {
  position: absolute;
  font-size: 18px;
  top: 25%;
  cursor: pointer;
  color: #1b344d;
  left: 55%;
}

#body .body_content .exam-question-info .box-list-question .list-question .active{
  background-color: #f37435;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 10px;
  left: 14px;
  top: 7px;
  text-align: center;
  line-height: 35px;
  transform: rotate(45deg);
}



#footer {
  color: #ffffff;
  background-image: url('footer.png');
  background-size: 100% 100%;
}

#footer h4 {
  text-align: left;
  font-weight: bold;
}

#footer .novagroup {
  width: 75%;
  text-align: justify;
  margin: auto;
}

#footer .novagroup .mxh2 img{
  padding-left: 25px;
}

#footer .contact p i{
  font-size: 18px;
  padding-right: 15px;
}

#footer .contact form input{
  margin: 5px;
}

#footer .contact form button{
  background-color: rgba(0,0,0,0);
  font-size: 16px;
  font-weight: bold;
  margin: 5px;
  padding: 4px 25px;
  border: 2px solid #ffffff;
}

#footer .contact form button:hover{
  background-color: #f37435;
  -webkit-transition:0.6s;
  -o-transition:0.6s;
  transition:0.6s;
  border: 2px solid #1b344d;
}

#footer .site {
  padding: 15px 0px 0px 15px;
}

#footer .site p{
  color: #f37435;
}

#footer .site p a{
  color: #ffffff;
}

#footer .site p a:hover{
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  color: #f37435;
}

#copyright {
  background-color: #122d36;
  color: #ffffff;
  padding: 10px;
  position: relative;
  font-family: Medium;
}

#copyright .left {
  width: 30%;
  left: 0px;
  position: absolute;
  bottom: 0px;
  z-index: 99;
  border-bottom: 1px solid #ffffff;
}

#copyright .right {
  width: 30%;
  right: 0px;
  position: absolute;
  bottom: 0px;
  z-index: 99;
  border-bottom: 1px solid #ffffff;
}

@media (max-width: 320px) {

  #header .header_mid_menu .container #navbar span {
    display: none;
  }
}

@media (max-width: 766px) {
  .navbar-nav .dropdown-menu .caret {
    transform: rotate(-90deg);
  }

  .contact_header {
    text-align: center;
  }

  .navbar-default {
    border-bottom: #EBEBEB;
  }

  .navbar-toggle {
    background-color: #f37435 !important;
  }

  .navbar-default .navbar-toggle .icon-bar {
    background-color: #77c050 !important;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 40px !important;
  }

  #header .header_mid_menu .container #navbar span {
    display: none;
  }

  #header .header_mid_menu .container .navbar-default .logo2 {
    display: block;
  }

  .navbar-default {
    border-bottom: #EBEBEB;
  }

  #body .body_content .tab {
    display: none;
  }

  #body .body_heading img {
    width: 100%;
  }

  #header .header_mid_menu .bong img {
    width: 100%;
  }


  #body .body_content .left .start {
    text-align: center !important;
  }

  #body .body_content #pills-do .checkbox .answers {
    width: 100%;
  }

  .checkmark {
    width: 70px;
  }

  .diem {
    right: 5px;
  }

  #body .body_content #pills-do .checkbox .check {
    padding-right: 0px;
    padding-top: 0px;
    padding-left: 0px;
  }

  #body .body_content #pills-do .checkbox .button_ask {
    padding-bottom: 30px;
  }

  #body .body_content #pills-do .checkbox .check .checkbox-inline:first-child {
    position: relative;
    top: 20px;
  }

  .radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
    margin-left: 0px;
  }

  #body .body_content #pills-ketqua .iframe {
    width: 100%;
    padding: 20px 0px 20px 0px;
  }
  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    font-size: 18px !important;
  }
  #body .body_content .nav-pills a:not(#pills-viewtest-tab):before{
    padding-right: 5px;
  }
}
.nav-item a{color: #000}

.box_category{
  display: flex;
  margin: 0 auto;
  width: 520px;
  @media (max-width: 520px) {
    display: flex;
    width: auto;
  }
}

.scroll-bar-wrap {
  width: 100%;
  position: relative;
  margin: 10px auto 0;
}
.cover-bar {
  position: absolute;
  background: #fff;;
  height: 100%;
  top: 0;
  right: 0;
  width: .4em;
  -webkit-transition: all .5s;
  opacity: 1;
}
.scroll-box{
  height: 300px;
  overflow: scroll;
}
.scroll-box::-webkit-scrollbar {
  width: .4em;
}
.scroll-box::-webkit-scrollbar,
.scroll-box::-webkit-scrollbar-thumb {
  overflow:visible;
  border-radius: 4px;
}
.scroll-box::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.2);
}
.scroll-bar-wrap:hover .cover-bar {
  opacity: 0;
  -webkit-transition: all .5s;
}
.button-default {
  background-color: transparent;
  border: none;
  outline: none;
  border: 1px solid #1b344d;
  padding: 5px 25px;
  border-radius: 10px;
  font-weight:bold;
}
.button-default:hover {
  background-color: #ff9900;
  color: #fff;
  transition: 0.4s;
}
#welcomModal h4{
  font-size: 24px;
  color: #f04504;
  font-weight: bold;
}
#welcomModal .modal-title{
  font-size: 28px;
  color: #f04504;
  font-weight: bold;
  background-image: url("../img/novai/bg-title.png");
  background-repeat:no-repeat;
  background-position: center center;
  background-position-y: 38px;
  height: 60px;
}
#welcomModal .modal-content{
  background: transparent;
  width: 800px;
  background-size: 100%;
  background-image: url("../img/novai/popup-bg.png");
}
.content-popup{
  padding: 40px 0;
}
#welcomModal .close{
  top: -5px;
  position: absolute;
  right: 9px;
  background: #ff9900;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  opacity: 1;
}
.row-results-email{
   background: #fff;
   .col-text .text-center{
      box-shadow: none;
   }
}
@media (max-width: 520px) {
  .content-popup{
    padding: 0;
  }
  #welcomModal .modal-content{
    width: 100%;
    background: #fff !important;
    background-image:none;
  }
  #welcomModal .modal-title {
    font-size: 24px;
  }
  #welcomModal h4{
    font-size: 18px;
  }
  .scroll-box{
    height: 200px;
    overflow: scroll;
  }
}
/* Css mới exam */
.test-diff {
        margin-top: 50px;
        text-align: center;
    }
    .test-diff-right {
        margin-right: 100px;
    }
    .test-diff-left {
        margin-left: 100px;
    }
    .test-diff p{
        color: #000;
    }
    .test-diff .main{
        
    }
    .test-diff .heading{
        padding: 20px;
    }
    .test-diff a{
        color: #ff0000;
        font-weight: bold;
    }
    .test-diff img{
        border-radius: 50%;
    }
    @media (max-width: 768px){
        .test-diff-right {
            margin-right: 0;
        }
        .test-diff-left {
            margin-left: 0;
        }
    }
