.register{
	margin-top: 50px;
	margin-bottom: 110px;
	@media only screen and (min-width : 320px) {
		background: #fff;
	}
	@media only screen and (min-width : 768px) {
		background: transparent;
	}
	.panel{
		border-color: #e6e7e8;
		border:0;
		box-shadow: none;
		.panel-heading{
			border-color: #f0f4f7;
			border-bottom-color: #ddd;
			background: #fff;
			font-size: 20px;
			color:#00a887;
		}
		.panel-body{
			padding: 0;
		}
	}
}

.footer{
	bottom:0;
}

.edus-login{
	text-align: center;
	h3{
		margin:0;
		font-size: 11px;
		hr{
			margin: 0;
		    margin-top: -6px;
		    border-top: 1px solid #c8d7e1;
		}
		span{
			background-color: #fff;
    		padding: 0px 10px;
    		color:#666;
			strong{
				background-color: #ec0e0d;
				padding:3px;
				color:#fff;
				border-radius: 2px;
				font-size: 12px;
			}
		}
		span.vg{
			color:#0d72b6;
			font-weight: bold;
			padding: 0;
			font-size: 12px;
		}
	}
	.horizon{
		margin-top: 20px;
    	margin-bottom: 20px;
	}
	.al_left{
		text-align: left;
	}
	button{
		width: 100%;
		font-size: 16px;
		border-radius: 50px;
		background-color: #ff9900;
		border-color: #ff9900;
		opacity: 1 !important;
	}
	.form-control{
		height:40px;
	}
	input.form-control{
		border-radius: 50px;
		&:focus{
			border:1px solid $brand-primary;
		}
	}
}

.loginwidthfacebook{
    background: #3b5a9a;
    border-color: #3b5a9a;
    &:hover{
    	background: #5873a9;
    	border-color: #3b5a9a;
    }
}
.loginwidthgoogle{
	background: #df4b38;
    border-color: #df4b38;
    &:hover{
		background: #e46656;
		border-color: #e46656;
    }
}
.loginwidthvgid{
	background: #3C5EAB;
    border-color: #3C5EAB;
    &:hover{
		background: #5072BF;
		border-color: #5072BF;
    }

    .fa-vgid{
    	background-image: url('../img/common/idvg.png');
    	background-repeat: no-repeat;
    	background-position: 10px 10px;
    	background-color: rgba(0, 0, 0, 0.1);
    	height: 38px;
    }
}

.loginwidthfacebook,
.loginwidthgoogle,
.loginwidthvgid{
    padding-left: 38px;
    color:#fff;
    font-size: 14px;
    padding:0;
    border-radius: 0;
	min-height: 36px;
	position: relative;
    i{
    	width: 38px;
    	line-height: 38px;
    	background: rgba(0, 0, 0, 0.1);
    	position:absolute;
    	left:0;
    	font-size: 20px;
    }
    span{
    	line-height: 38px;
    }
    &:hover{
    	color:#fff;
    }
}

.login_logo{
	text-align: center;
	margin-bottom: 10px;
	a.logo{
		margin: 0 auto;
	}
	p.title{
		font-size: 18px;
		color: #333;
		font-weight: bold;
	}
	.icon-user-login{
		text-align: center;
		width: 50px;
		margin: 0 auto;
		img{
			width: 100%;
		}
	}
}

.foot_login{
	a{
		font-size: 12px;
		color: #333;
		margin-top: 15px;
		&:hover{
			color:#333;
		}
	}
}
.login-backdrop-register{
	height: 618px;
}
.login-backdrop{
	background: #000;
	text-align: center;
	position: relative;
	height: 538px;
	.img-backdrop{
		height: 100%;
		width: 100%;
		opacity: 0.4;
	}
	.text_over_image{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		text-align: justify;
		width: 100%;
		padding: 20px;
		img{
			width: 150px;
		}
		.tiny-sub{
			font-size: 12px;
		}
	}
}

.wrap-form{
	background: #fff;

	@media only screen and (min-width : 320px) {
		padding-right: 0px;
	}

	@media only screen and (min-width : 768px) {
		padding-right: 30px;
	}

}