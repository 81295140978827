/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    .navbar-unibee{
        //max-height: 50px;

        .navbar-brand{
            top: 0;
            padding:0;
            transform: translateX(-50%);
            left: 50%;
            position: absolute;
            z-index: 1;
        }

        .navbar-toggle{
            z-index:3;
        }

        .avata-login-mobile{
            float: right;
            margin: 6px 15px;
            padding: 0;

            @media only screen and (min-width : 320px) {
                margin-left: 0;
            }

            @media only screen and (min-width : 480px) {
                margin-left: 15px;
            }
        }

        .avata-login{
            border:0;
            background:transparent;
            img{
                width: 30px;
                height: 30px;
                display: inline-block;
            }
        }

        .nav-login{
            .btn-login{
                color: $brand-primary;
                border: 1px solid $brand-primary;
                font-size: 12px;
                padding: 10px;
                line-height: 10px;
                margin: 9px 15px;
                &:hover,
                &:focus,
                &:active{
                  background-color: $brand-primary !important;
                  color: #fff !important;
                }
            }
            li{
                &:first-child{
                    a{
                        margin-right: 0;
                    }
                }
            }
            .dropdown-logged{

                button{
                    margin: 9px 15px;
                    padding:0;
                    .caret{
                        color: $brand-primary;
                    }
                }

                .icon-notify {
                    margin-left: 15px;
                    color: $brand-primary;
                    cursor: pointer;
                    font-size: 17px;

                    &:hover{
                        color: #333;
                    }
                }
            }
        }

        .navbar-nav {
            > .open > a {
              &,
              &:hover,
              &:focus,
              &:active {
                background-color: transparent;
                color: $brand-primary;
              }
            }
        }

        .navbar-collapse-unibee{
            position: relative;
            background: #fff;
            .nav li,
            .nav li a{
                position:static;
            }

            .dropdown-add{
                li{
                    a{
                        font-size: 12px;
                        padding: 5px 10px;
                        margin: 10px 15px;
                    }
                }
                .btn-add-become{
                    color: #fff;
                    border: 1px solid $brand-primary;
                    font-size: 14px;
                    text-transform: uppercase;
                    &:hover{
                        color: #fff;
                        background-color: darken($brand-primary, 10%);
                    }
                }
                .btn-test{
                    /*color: #01a64f;
                    border: 1px solid #01a64f;*/
                    background-color: #01a64f;
                    color: #fff!important;
                    font-size: 14px;
                    &:hover{
                        background-color: darken(#01a64f, 10%) !important;
                    }
                }
            }
        }

        .search-mobile{
            float: right;
            margin: 5px 0px;
        }

        .menu-toggle{
            float: left;
            margin: 5px 15px;
            padding: 0;
        }
    }
}
.avata-login-mobile{
    width: 80px;
}
.navbar-eguide {
    background: rgba(0,0,0,0.5);
    border: 0;

    .navbar-nav > .open > a,
    .navbar-nav > .open > a:hover,
    .navbar-nav > .open > a:focus {
        background-color: #D56B37;
        color: #fff;
    }
    .navbar-brand {
        @media only screen and (min-width : 320px) {
            padding-top: 0;
            padding-bottom:0;
            padding-left: 55px;
            padding-right: 0;
        }

        @media only screen and (min-width : 768px) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .logo-default{
        background-position: bottom;
    }

    .navbar-nav > li > a {
        color: #fff;
    }

    #menu-toggle{
        //position: fixed;
        //top: 12%;
        background: #f57933;
        color: #fff;
        padding: 8px 8px;
        float: left;
        @media only screen and (min-width : 320px) {
            //top: 0%;
        }
    }

    #menu-use{
        color: #fff;
        /* position: fixed; */
        border: 0;
        /* right: 0px; */
        /* top: 0px; */
        background: #f77736;
        padding: 8px 10px;
        float: right;
        @media only screen and (min-width : 320px) {
            padding: 12px 10px 11px 10px;
            font-size: 11px;
        }
    }

    #menu-toggle{
        //left: 0;
        font-size:0px;
        .hamburger{
            i{
                height: 6px;
                width: 40px;
                background: #fff;
                border-radius: 2px;
                display: block;
                margin-top: 5px;
            }
        }
    }

    #menu-search{
        float: right;
        padding: 10px 20px;
        color: #999;
        margin-right: 100px;
        span{
            font-size: 29px;
        }
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}


.navbar-mobile-search{
    height: 100%;
    background: rgba(0,0,0,0.8);
    display:none;

    .popup-mobile-search{
        margin: 5px;
        input{
            height: 40px;
            border:none;
            border-radius: 0;
            border-top-right-radius: 2px !important;
            border-bottom-right-radius: 2px !important;

            &:active,
            &:hover,
            &:focus{
                border-color: $brand-primary;
                outline:none;
            }
        }
        span{
            cursor:pointer;
        }
        .easy-autocomplete-container{
            position: fixed;
            left: 0;
            margin: 0px 5px;
            width:auto;
        }
    }
}
.group-none{
    border: 1px solid #dce4ec !important;
    border-left: 0px !important;
}
.group-reset{
    border: 1px solid $brand-primary !important;
    border-left: 0px !important;
}

.dropdown-menu .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    margin-top: -1px;
}

.dropdown-menu{
    li.child > {
        a:after{
            display: block;
            content: " ";
            float: right;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px 0 5px 5px;
            border-left-color: #00983B;
            margin-top: 5px;
            margin-right: -10px;
        }
    }
    li{
        a{
            padding: 10px 20px;
        }
    }
}

.dropdown-menu li:hover .sub-menu {
    visibility: visible;
    display: block;
}


.nav-tabs .dropdown-menu, .nav-pills .dropdown-menu, .navbar .dropdown-menu {
    margin-top: 0;
}

.navbar .sub-menu:before {
    border-bottom: 7px solid transparent;
    border-left: none;
    border-right: 7px solid rgba(0, 0, 0, 0.2);
    border-top: 7px solid transparent;
    left: -7px;
    top: 10px;
}
.navbar .sub-menu:after {
    border-top: 6px solid transparent;
    border-left: none;
    border-right: 6px solid #fff;
    border-bottom: 6px solid transparent;
    left: 10px;
    top: 11px;
    left: -6px;
}

@media only screen and (min-width: 768px) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
}