.table-road-map{
  tr,th{
    border: 1px solid #e37f0c !important;
    td{
      border: 1px solid #e37f0c !important;
      vertical-align: middle !important;
      input[type=text], textarea{
        border: none;
        width: 100%;
      }
    }
  }
  .headingtr{
    text-align: center;
    font-weight: bold;
  }
  a{
    color: #0a9151;
  }
  .head{
    background: #ff9900;
    color: #fff;
    td{
      border: 1px solid #ff9900;
    }
    h3{
      font-size: 20px;
    }
  }
}