@charset "utf-8";
/**Ver 1.2**/
/** Phần config chung **/
body,html{
	width:100%;
	height:100%;
}

@media screen and (min-width: 768px) and (max-width:1200px) {
	body,
	.container {
		width: 1200px !important;
	}
}

.body-auth{
	background: #e9e9e9;
}

a:hover, a:focus{
	color: $brand-primary;
}

.body-home,
.body{
	height:100%;
	position:relative;
}

.body-home{
	background: #b3b3b3;
}

.no-padding{
	padding:0;
}

.no-margin{
	margin:0;
}

.no-margin-top{
	margin-top: 0;
}

.no-border{
	border:0;
}

.no-bg{
	background:transparent;
}

.mr_5{
	margin-right: 5px;
}

.ml-5{
	margin-left: 5px;
}

.bg-while{
	background: #fff;
}

.divider{
	width: 50px;
	background: $brand-primary;
	height: 4px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.text-logo{
	.first{
		color: #f37935;
	}
	.second{
		color: #7cc150;
	}
}

.overlay,
.overlay-x{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.8);
    z-index: 100;
}

.overlay-search{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	z-index: 1000;
}

#home{
	padding-top: 0px;
}

.bg-home{
	background-position: top;
}

.text-overflow{
	white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.center-text-to-div{
	margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.border-l{
	border-left: 1px solid #e4e4e4;
}

.border-r{
	border-right: 1px solid #e4e4e4;
}

.border-radius-base{
	border-radius: 3px;
}

.select-control{
	border: 1px solid #dce4ec;
    border-radius: 4px;
}

.reset-panel{
	border:0;
	box-shadow: none;
}

.nova-panel{
	.panel-body{
		padding-left: 0;
		padding-right: 0;
	}
}

//btn unibee
.btn-find{
	min-width: 70px;
	i{
		font-size: 22px;
		margin:0;
	}
}

//line
.line-pattern{
	height: 5px;
    background-image: url("../img/parttern_line.png");
    display:block;
}
.line-break{
	height: 1px;
    background-color: #e4e4e4;
    margin-bottom: 10px;
}
.line-break-bold{
	height: 1px;
    background-color: #ddd;
}

// Danh sách khóa học
.course-grid{
	margin-bottom: 110px;

	@media only screen and (min-width : 480px) {
		margin-top: 20px;
	}

	@media only screen and (min-width : 768px) {
		margin-top: 30px;
	}

	.course-item{
		@media only screen and (max-width : 480px) {
			width:auto;
		}
		@media only screen and (min-width : 480px) {
			
		}
	}
	.course-item:hover .thumbnail .price{
		background: #f58634;
	}
	
	.thumbnail{
		padding: 0;
		border: 0;
	    background-color: #fff;
	    position:relative;
		border-radius: 5px 5px 10px 10px;

	    .block-img{
	    	display: block;
	    }
	    &:hover img.cou-pic{
			opacity: 0.5;
		}

	    .caption{
	    	padding:10px 15px;
			border: 1px solid #ccc;
			h3{
				font-size: 16px;
				font-weight: bold;
				height: 40px;
				display: -webkit-box;
				line-height: 20px;
			    -webkit-line-clamp: 2;
			    -webkit-box-orient: vertical;
			    overflow: hidden;
			    margin-bottom: 5px;
			    a{
			    	color:#333;
			    	&:hover{
			    		color: $brand-primary;
			    	}
			    }
			}
			.description{height: 100px; overflow: hidden; margin-bottom: 10px}
			.hometext{height: 58px; overflow: hidden; margin-bottom: 10px}
			.star-vote{
				margin-bottom: 5px;
			}
			
			.price,.free{
			    text-align: right;
			    font-size: 15px;
			    width: 50%;
			    margin-bottom: 5px;
			    line-height: 20px;
			    font-weight: bold;
			}

			.free{
				color: $brand-primary;
			}

			.price{
				color:$brand-primary;
			}

			.u-name{
				a{
					color: #727376;
				}
			}

			.btn-normal{
				padding-top: 3px;
				padding-bottom: 3px;
			}
			
	    }

	    .price{
			padding: 6px 10px;
			color: #fff;
			font-size: 14px;
			right: 0;
			margin-bottom: 0;
			font-weight: bold;
			background: #1a344d;
			text-align: center;
		}

	    @media only screen and (min-width : 320px) {
			margin-bottom: 15px;
		}
		@media only screen and (min-width : 768px) {
			margin-bottom: 30px;
		}
	}

	.thumbnail-outline{
		background-color: transparent;
		box-shadow: none;

		.date_view{
			color: #999;
    		font-size: 12px;

    		i{
    			padding-right: 5px;
    			color: #87a6bc;
    		}
		}
	}

	.thumbnail-reset{
		.block-img{
			padding: 0;
			img{
				border-radius: 3px;
			}
		}
	}

	.more-option{
		position:relative;
		.block-img{
			visibility: hidden;
		}
	}

	.wrap-more{
		position: absolute;
	    top: 0;
	    width: 100%;
	    height: 100%;
	}

	.thumbnail-more{
		border-radius: 4px;
		display:table;
		width:100%;
		height:100%;
		color: #999999;
		background:#fff;
		

		&:hover{
			color:#333;

			a{
				color:#333;

				span{
					color:#333;
				}
			}
		}
		    
		a{
			text-align: center;
    		cursor: pointer;
    		font-size: 15px;
    		font-weight: bold;
    		display:table-cell;
    		vertical-align: middle;
    		color:$brand-primary;

    		p{
    			display:table;
    			margin: 10px auto;
    			margin-top: 30px;

    		}

    		i{
    			background: #eeeae5;
			    border: 10px solid #f3f3f3;
			    border-radius: 50%;
			    width: 150px;
			    height: 150px;
			    display: table-cell;
			    vertical-align: middle;
			    font-size: 60px;
    		}
    		span{
    			font-size: 12px;
    			text-transform: uppercase;
    			margin-top: 10px;
    			display:block;
    			margin-bottom: 30px;
    		}
		}
	}
}

//Danh sách doanh nghiệp
.enterprise-grid{

	@media only screen and (min-width : 480px) {
		margin-top: 20px;
	}

	@media only screen and (min-width : 768px) {
		margin-top: 30px;
	}

	.item{
		padding-left: 5px;
		padding-right: 5px;

		@media only screen and (max-width : 480px) {
			width:auto;
		}

		@media only screen and (min-width : 480px) {

		}
	}
	.item:hover .thumbnail .price{
		background: #f58634;
	}

	.thumbnail{
		padding: 0;
		border: 0;
		background-color: #fff;
		position:relative;
		border-radius: 5px 5px 10px 10px;

		.block-img{
			display: block;
			height: 160px;
			width: 100%;
			img{
				width: 100%;
				height: 100%;
			}
		}

		&:hover img.cou-pic{
			opacity: 0.5;
		}

		.caption{
			padding:10px 15px;
			border: 1px solid #ccc;
			h3{
				height: 40px;
				font-size: 16px;
				font-weight: bold;
				display: -webkit-box;
				line-height: 20px;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				a{
					color: #254f5b;
					text-transform: uppercase;
					&:hover{
						color: $brand-primary;
					}
				}
			}
		}

		@media only screen and (min-width : 320px) {
			margin-bottom: 15px;
		}

		@media only screen and (min-width : 768px) {
			margin-bottom: 30px;
		}

	}
}

.course-list{
	border-radius: 0;
	
	.media{
		padding-bottom: 15px;
		border-bottom: 1px solid #ddd;

		.media-left{
			@media only screen and (min-width : 320px) {
				display: block;
    			margin-bottom: 10px;
    			padding-right: 0;
    			a{
    				width:100%;
    			}
			}
			@media only screen and (min-width : 480px) {
				display: table-cell;
    			margin-bottom: 0px;
    			padding-right: 10px;
    			a{
    				width:180px;
    				height:110px;
    			}
			}
			a{
				display:block;
				margin-right: 10px;
				img{
					width: 100%;
				}
			}
		}
		.media-body{
			.media-heading{
				display: -webkit-box;
				-webkit-line-clamp: 2;
			    -webkit-box-orient: vertical;
			    overflow: hidden;
			}
			a{
				font-size: 13px;
				color:#333;
				font-weight: bold;

				&:hover,
				&:active{
					color:$brand-primary;
				}
			}
			p{
				margin:0;
				line-height: 21px;
			}
			.vote-list{
				width:100%;
				.gen-star{
					margin:0;
				}
				.price_course{
					margin-top:2px;
				}
			}
			.price_course{
				color:$brand-primary;
				font-size: 13px;
				font-weight: bold;
			}
			.tech_name{
				font-size: 12px;
			    color: #666;
			    margin-bottom: 5px;
			    a{
			    	color:#333;
			    	font-size: 12px;
			    	&:hover{
			    		color:$brand-primary;
			    	}
			    }
			}
			a.c_edit{
				color:#999;
				font-size: 14px;

				&:hover{
					color:#333;
				}
			}
			.count-use{
				margin-top: 2px;
			}
			.report_stt{
				margin-top: 20px;
    			line-height: 15px;

				i{
					font-size: 16px;
					padding-right: 5px;
					display: inline-block;
					vertical-align: text-bottom;
				}
				i.fa-lock,i.fa-hourglass-half{
					color:#87a6bc;
				}
				span{
					font-size: 12px;
					color:#666666;
					margin-right: 15px;
					display:inline-block;
					line-height: 22px;
				}
				.view_demo{
					color:$brand-primary;
					display:inline-block;
					i{
						color:$brand-primary;
					}
					&:hover,&:hover i{
						color:#00bb77;
					}
				}
			}
		}
	}
	.media:last-child{
		padding:0;
		border: 0;
	}
}


//Danh sách tài liệu
.doc-item{
	.thumbnail{
		box-shadow: none;
		&:hover{
			box-shadow: none;
		}
		.caption{
			padding-left: 0;
			padding-right: 0;
			h3{
				font-size: 15px;
				font-weight: normal;
				height: 40px;
				display: -webkit-box;
				line-height: 20px;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				margin-bottom: 5px;
				a{
					color: #033333;
					&:hover{
						color: $brand-primary;
					}
				}
			}
			.doc-price{
				font-size: 17px;
				width: 50%;
				margin-bottom: 5px;
				line-height: 20px;
				font-weight: bold;
				color:$brand-primary;
			}
			.doc-user{
				a{
					font-size: 12px;
					color: #00aa55 !important;
					font-weight: bold;
				}
			}
		}
	}
}

//Danh sách bài test
.exam-result-item{
	.exam-result{
		font-size: 20px;
		color: #00aa55;
		font-weight: bold;
	}
	.btn{
		padding-top: 7px;
		padding-bottom: 7px;
	}
	.thumbnail{
		a{
			display: block;
			position: relative;
			.exam-correct{
				font-size: 50px;
				color: #80d4aa;
			}
		}
	}
}

// breadcrumd
.unibee-breadcrumb{
	
	h3,h1{
		display: block;
	    width: 100%;
	    float: left;
	    color: #333;
	    line-height: 20px;
	}

	@media only screen and (min-width : 320px) {
		min-height: 40px;
		h3,h1{
			font-size: 18px;
			line-height: 22px;
		}
	}
	@media only screen and (min-width : 480px) {
		h3,h1{
			font-size: 25px;
			line-height: 30px;
		}
	}

	.breadcrumb {
		margin-bottom: 0;
		background:transparent;
		
		@media only screen and (min-width : 320px) {
			padding:10px 0;
		}
		@media only screen and (min-width : 480px) {
			padding:5px 0 15px;
		}

		li{
			a{
				font-size: 11px;
				color: #aaaaaa;
				display: inline-block;
				text-transform: uppercase;

			    &:hover{
			    	color:#333;
			    }
			}
		}
		li.active{
			color: #f58634;
			text-transform: uppercase;
          	font-size: 14px;
		}
		> li + li:before{
			font-size: 11px;
    		color: #aaaaaa;
    		padding:0;
		}
	}
}

.novai-breadcrumb{

	h3,h1{
		display: block;
		width: 100%;
		float: left;
		color: #333;
		line-height: 20px;
	}

	@media only screen and (min-width : 320px) {
		margin-bottom: 15px;
		margin-top: 15px;

		h3,h1{
			font-size: 18px;
			line-height: 22px;
		}
	}

	@media only screen and (min-width : 480px) {
		h3,h1{
			font-size: 25px;
			line-height: 30px;
		}
	}

	.breadcrumb {
		margin-bottom: 0;
		background:transparent;

		@media only screen and (min-width : 320px) {
			margin-bottom: 10px;
			padding:10px 0;
		}
		@media only screen and (min-width : 480px) {
			padding: 0;
		}

		li{
			a{
				font-size: 11px;
				color: #aaaaaa;
				display: inline-block;
				text-transform: uppercase;

				&:hover{
					color:#333;
				}
			}
		}

		li.active{
			color: #f37435;
			text-transform: uppercase;
			font-size: 12px;
			background: none;
		}

		> li + li:before{
			font-size: 11px;
			color: #aaaaaa;
			padding:0;
		}
	}

	.btn-loc{
		background: #F37335;
		height: 32px;
	}
	.btn-sort{
		background: #fff;
		border: 1px solid #ccc;
		color: #333;
	}
	.btn-search{
		padding: 7px 15px;
	}
	.input-search{

		@media only screen and (min-width : 320px) {
			width: 100%;
		}

		@media only screen and (min-width : 768px) {
			width: 200px;
		}
	}
	.btn-loc,.btn-search,.input-search{
		height: 32px;
	}
	.input-search,.btn-loc,.btn-sort{
		margin-right: 3px;
	}

	.main-title{
		color: #254f5b;
		text-transform: uppercase;
		font-weight: bold;

		@media only screen and (min-width : 320px) {
			margin-bottom: 20px;
		}

		@media only screen and (min-width : 768px) {
			margin-bottom: 0px;
		}
	}

	.btn-search{
		@media only screen and (min-width : 320px) {
			width: 100%;
		}

		@media only screen and (min-width : 768px) {
			width: auto;
		}
	}
}

.detail-breadcrumb{
	h1{
		padding-bottom: 10px;
		font-size: 18px;
	}
}

.unibee-breadcrumb-registered{
	background: #01a64f;
	.breadcrumb li a{
		color: #fff;
	}
	.breadcrumb li.active{
		color: #fff;
	}
	.breadcrumb li:before{
		color: #fff;
	}

	h1{
		color: #fff;
		margin-bottom: 20px;
	}
}

.search-unibee{
	margin: 6px 0px;
	@media only screen and (min-width : 992px) {
		.input-group{
			.input-group-btn{
				width:1%;
			}
		}
		.input-group{
			width:440px;
		}
	}
	input{
		height: 37px;
    	padding: 5px 15px;
    	border-right: none;

    	&:active,
    	&:hover{
    		border-color: #dce4ec;
    		outline:none;
    	}

    	&:focus{
    		border-color: $brand-primary;
    		outline:none;
    	}
	}

	.icon-btn-input{
		padding: 4px 8px;
	    color: #fff;
	    border-radius: 4px !important;
	    margin-right: 4px;

	    &:active,
	    &:hover,
    	&:focus{
    		color: #ccc;
    	}
    	i{
    		margin:0;
    	}
	}

	.input-group-btn{
		border: 1px solid #dce4ec;
	    border-left: 0;
	    border-top-right-radius: 3px;
	    border-bottom-right-radius: 3px;
	}
}

//Style các select option
.bootstrap-select
{
	width:100% !important;
	.dropdown-toggle:focus{
		outline: none !important;
	}
	.dropdown-toggle{
		background:#fff;
		color:#333;
		border: 1px solid #ddd;
		&:hover,
		&:active{
    		background-color: #fff;
    		border-color: #ddd;
		}
	}
}
@media only screen and (min-width : 992px) {
	.bootstrap-select.dr-small{
		.dropdown-toggle{
			padding-top: 7px;
		    padding-bottom: 7px;
		}
	}
}

.notification{
	position:relative;
	display:inline-block;
	margin:12px 15px;
	margin-right: 3px;
	.icount{
		position: absolute;
	    top: -10px;
    	right: -10px;
    	left: 10px;

	    .num-count{
	    	border-radius: 2px;
    		box-shadow: 0 1px 1px rgba(0, 0, 0, .7);
    		background-color: #333;
    		background-image: linear-gradient(#666, #000);
		    color: #fff;
		    padding: 1px 3px;
		    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
		    font-size: 11px;
		    line-height: 1.3;
    		min-height: 13px;
	    }
	}
}

.notification.iscount{
	margin-right: 15px;
}

.dropdown-notification{
	
	@media only screen and (min-width : 320px) {
		min-width: 320px;
		max-width: 320px;
	}

	@media only screen and (min-width : 480px) {
		min-width: 480px;
		max-width: 480px;
	}
	
	padding:0;
	border: 0;

	li{
		a{
			padding:10px;
			padding-right: 50px;

			&:hover{
				background:transparent;
				color:$brand-primary;
			}
		}
	}

	li.head{
		h4{
			border-bottom: solid 1px #ccc;
			font-size: 15px;
    		font-weight: bold;
    		margin:0;
    		padding:8px 10px;
    		display:table;
    		width:100%;
    		a,span.title{
    			display:table-cell;
    			vertical-align: middle;
    		}
    		a{
    			font-size: 11px;
			    color: #999;
			    float: right;
			    padding-right: 0;
			    padding:5px 0px;
			    &:hover{
			    	color:#333;
			    }
    		}
		}
	}

	li.noti-null{
		span{
			display:block;
			text-align: center;
			color:#999;
			padding:20px 10px;
		}
	}

	li.foot{
		background-color: #eeeae5;
		text-align: center;
		a{
			border-top: solid 1px #ccc;
			font-weight: bold;
			font-size: 12px;
			padding:6px 15px;
			color:#999;
			&:hover{
		    	color:#333;
		    }
		}
	}
}

.notification-list{
	.scrollable{
		max-height: 220px;
		overflow-y:auto;
	}
	a{
		padding: 8px 10px;
		display:block;
		color:#333;
		overflow:hidden;
		border-bottom: solid 1px #ddd;
		&:hover{
			background:#f5f5f5;
		}
		
	}
	a.is-read{
		background:#eeeae5;
		&:hover{
			background:#f5f5f5;
		}
	}

	.img-use-noti{
		width:35px;
		height:35px;
		float:left;
		margin-right: 10px;
		img{
			border-radius: 50%;
			width:100%;
			height:100%;
		}
	}

	.noti-content{
		font-size: 12px;
		p{
			margin:0;
			color:#333;
		}
		p.time{
			color:#999;
		}
	}

	.item-notify{
		position:relative;
		&:hover .option{
			display:block;
		}
	}

	.option{
		position: absolute;
	    top: 12px;
	    right: 15px;
	    display:none;

	    span{
	    	color:#999;
	    	cursor:pointer;
	    	display:inline-block;
	    	z-index:1000; 
	    	position:relative;
	    	&:hover{
	    		color:#333;
	    	}
	    }
	}
}

.notifi-head{
	h3{
		display: table;
    	width: 100%;
    	.title,a {
    		display: table-cell;
    		vertical-align: middle;
    	}
    	a{
    		color:#999;
    		text-align: right;
    		font-size: 14px;
    		&:hover{
		    	color:#333;
		    }
    	}
	}
}


//nav-bar-outline
.navbar-outline{
	background:transparent;
	border:1px solid transparent;
	.navbar-nav {
		border-right: 1px solid transparent;
		border-left: 1px solid transparent;
		>li{
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
		}
	    > li > a {
	      color: #fff;
	    }
	}
	.search-unibee{
		display:none;
	}
	.btn-add-become{
		background:transparent;
		&:hover{
			background:#fff !important;
			color:$brand-primary !important;
		}
	}

	.icon-notify{
		color:#fff !important;

		&:hover{
			color:$brand-primary !important;
		}

		.num-count{
			background-color: $brand-primary;
    		background-image: linear-gradient($brand-primary, $brand-primary);
    		box-shadow: none;
		}
	}
	.btn-login{
		color: #fff !important;
		border-color: #fff !important;

		&:hover{
			border-color: $brand-primary !important;
		}
	}
	
}

/****************/


/** Chèn logo **/

.icon-play{
    width: 28px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
}

.sidebar-login{
	.divider{
		height: 1px;
	    margin: 9.5px 0;
	    overflow: hidden;
	    background-color: #e5e5e5;
		width: 100%;
	}
	li.m-login{
		line-height: 30px;
		margin:0px 15px;
		border-bottom: 1px solid #eae6e6;
		a{
			color:#333;
			&:hover{
				color:$brand-primary;
			}
			i{
				margin-right: 5px;
			}
		}
	}
	li.not-login{
		a{
			border:1px solid $brand-primary;
			display:block;
			margin:15px;

			&:hover{
				background:$brand-primary;
				color:#fff;
			}
		}
	}
}

.m-profile{
	.m-cover{
		height:92px;
	}
	.m-info{
		@media only screen and (min-width : 320px) {
			margin-top: -25px;
		}
		@media only screen and (min-width : 480px) {
			margin-top: 25px;
		}
		img{
			width:50px;
			height:50px;
			display:block;
			margin:0 auto;
		}
		p.name{
			font-size: 20px;
			margin:0;
			a{
				color: #00a888;
			}
		}
		p.obj-repo{
			font-size: 13px;
			line-height: 18px;
		}
	}
}


.star-vote{
	.fa-star.on{
		color: $brand-primary;
	}
	.fa-star.off{
		color: #e4e4e4;
	}
}

.num-vote{
	color: #fff;
	margin-left: 10px;
	line-height: 30px;
}

.count-use{
	width:100%;
}
.star-vote,
.count-use,
.calendar{
	font-size: 12px;
	margin-bottom: 10px;
	.fa{
		margin-right: 5px;
	}
}
.star-vote,
.count-use{
	color: #999;
}
.calendar{
	i{
		color: #87a6bc;
	}
}

.star-vote-medium{
	font-size: 14px;
}

.use-info{
	min-width: 150px;
	overflow:hidden;
	a.u-avatar{
    	margin-right: 10px;
	}
	.u-name{
		font-size: 12px;
		text-align: left;
		margin:0;
		a{
			color: #333;
			display:block;
			font-weight: bold;
			&:hover{
				color:$brand-primary;
			}
		}
		span{
			color: #999999;
    		font-size: 11px;
    		white-space: nowrap;
		    display: block;
		    text-overflow: ellipsis;
		    overflow: hidden;
		}
	}
}
.use-info-small{
	a.u-avatar{
		width:30px;
		height:30px;
	}
}
.use-info-medium{
	a.u-avatar{
		width:60px;
		height:60px;
	}
}
.use-info-lag{
	a.u-avatar{
		width: 80px;
    	height: 80px;
	}
}
.use-info-search{
	margin-bottom: 20px;
	.u-name{
		font-size: 15px;

		span{
			font-size: 13px;
		    line-height: 16px;
		    display:block;
		    margin-top: 10px;
		}
	}
}
.view-more{
	background:$brand-primary;
	padding:10px;
	text-align: center;
	display:block;
	color: #fff;
	&:hover,
	&:focus,
	&:active{
		color: #fff;
		background:darken($brand-primary, 17%)
	}
}
.social{
	margin-right: 5px;
	a{
		color: #ccc;
		display:inline-block;
	}
	a.twitter-sign{
		&:hover{
			color:#3F8DCB;
		}
	}
	a.google-plus-sign{
		&:hover{
			color:#DC4E41;
		}
	}
	a.facebook-sign{
		&:hover{
			color:#305891;
		}
	}
	a.youtube-sign{
		&:hover{
			color:#CC181E;
		}
	}
	a.linkedin{
		&:hover{
			color:#0077B5;
		}
	}
}
.social-medium{
	font-size: 35px !important;
}
.social-circle{
	a{
		width:30px;
		height:30px;
		text-align: center;
		display: table;

		i{
			color:#fff;
			display: table-cell;
			vertical-align: middle;
			font-size: 17px;
		}
	}
	a.facebook-sign{
		background:#0a4e8e;
		&:hover{
			background: darken(#0a4e8e, 10%);
		}
	}
	a.twitter-sign{
		background:#0b6aad;
		&:hover{
			background: darken(#0b6aad, 10%);
		}
	}
	a.google-plus-sign{
		background:#cd121c;
		&:hover{
		  background: darken(#cd121c, 10%);
		}
	}
	a.linkedin{
		background:#008bc8;
		&:hover{
		  background: darken(#0077b5, 10%);
		}
	}
	a.unibee-send-mess{
		background:#00A888;
		&:hover{
		  background: darken(#00A888, 10%);
		}
	}

}

.list-lecture-item,
.list-lecture-item-success{
	li{
		a{
			border-bottom: 1px solid #e4e4e4;
			border-bottom-style: dotted;
			display:block;
			color:#333;

			i{
				color:#e0e0e0;
				font-size: 14px;
				margin-right: 5px;
			}

			span{
				color: #f90;
				font-weight: bold;
				@media only screen and (min-width : 320px) {
					font-size: 12px;
				}
				@media only screen and (min-width : 480px) {
					font-size: 14px;
				}
				
			}

			&:hover,
			&:hover i,
			&:hover span{
				color: $brand-primary;
			}
		}

		h4{
			@media only screen and (min-width : 320px) {
				font-size: 16px;
			}
			@media only screen and (min-width : 480px) {
				font-size: 13px;
			}
			font-weight: bold;
			margin-bottom: 0;
			padding: 10px;
		    background: $brand-primary;
		    color: #fff;
		}
		small{
			color: #999;
			font-size: 11px;
			padding:10px;
			display: block;
    		border-bottom: 1px solid #e4e4e4;
		}
	}
}

.list-lecture-item{
	li{
		a{
			display:block;
			padding: 8px;
			span{
				float: right;
			}
		}
	}
	li.item-group{
		margin-top: 30px;
		&:first-child{
			margin-top: 0;
		}
	}
}

.list-lecture-item-success{
	li{
		position:relative;

		a{
			border-left: 1px solid #f0f0f0;
			margin: 0px 25px;
			padding: 10px 20px;

			span{
				display:block;
				line-height: 20px;
			}
		}

		.icon-lec-success{
			left:13px;
			top:10px;
			border-radius: 50%;
			font-size: 12px;
			color:#666666;
			background: #fff;
		   	border: 1px solid #f0f0f0;
		   	padding: 1px;
		   	position: absolute;
		   	z-index: 1;
		   	width: 25px;
			height:25px;
			span{
	   			display: inline-block;
				overflow: hidden;

				&:before {
					font-family: FontAwesome;
				  	background: $brand-primary;
				  	border-radius: 50%;
				  	content: "";
				  	float: left;
				  	height: 21px;
				  	width: 21px;
				  	color: #fff;
				  	text-align: center;
				  	line-height: 18px;
				  	font-size: 11px;
				}
	   		}
	   		i{
		   		position: absolute;
			  	left: 5px;
			  	font-style: normal;
			  	color: #333;
			  	font-size: 11px;
			  	line-height: 22px;
			  	top:0;
		   }
		}
	}
	li.item-group{
		h4,small{
			padding: 8px 20px;
		}
		h4{
			padding-bottom: 0;
			background:#fff;
			color:#333;
		}
		small{
			padding-top: 0px;
		}
	}
	li.active{
		a,i,span{
			color: $brand-primary;
		}
	}
}

.aside-review{
	table{
		width:100%;
		tr {
			td{
				.point{
					font-size: 50px;
					color:#ffbb11;
				}
				@media only screen and (min-width : 320px) {
					.star-vote .num-vote{
						display:block;
					}
					.review-order span.longstar{
						min-width: 40px;
					}
				}
				@media only screen and (min-width : 480px) {
					.star-vote .num-vote{
						display:inline-block;
					}
					.review-order span.longstar{
						min-width: 120px;
					}
				}
			}
		}
	}
}

.review-order{
	span{
		display:inline-block;
		color:#999;
		font-size: 12px;
		margin-right: 5px;
	}
	span.longstar{
		background-color: #e4e4e4;
		height:10px;
		min-width: 120px;

		.long{
			float: left;
			height: 100%;
			background-color: #ffbb11;
			width: 40%;
		}
	}
}
.reviews-list{
	h3{
		font-size: 14px;
	    color: #333;
	    text-transform: uppercase;
	    margin-bottom: 15px;
		margin-top: 35px;
	}
	.media-list-reviews{
		.media-left{
			a{
				width:30px;
				height:30px;
				display:block;
			}
		}
		.media-body{
			.media-heading{
				margin-bottom: 0px;
    			line-height: 14px;
    			margin-bottom: 5px;
    			a{
    				color: #333;
				    font-size: 13px;
				    display: inline-block;
				    vertical-align: top;
				    font-weight: bold;
				    &:hover{
				    	color:$brand-primary;
				    }
    			}
    			.time{
					color:#999999;
					font-size: 11px;
					margin-bottom: 5px;
					vertical-align: top
				}
			}
			.label{
				font-size: 11px;
			}
			.review-star{
				width: 100%
			}
			.content{
				color: rgb(51, 51, 51);
			    font-size: 12px;
			    line-height: 18px;
			    font-weight: normal;
			    margin-top: 5px;

			}
			.star-vote{
				.fa{
					margin-right: 0;
				}
			}

		}
	}
}

.nav-tabs-unibee{
	border: 1px solid #ddd;
    border-bottom: 0;

	> li > a {
	    color: #999999;
	    border-radius: 0px 0px 0px 0px !important;
	    font-size: 13px;
	    font-weight: bold;
	    text-align: center;
	    
	    &:hover,
	    &:active{
	    	color:#fff;
	    	background:$brand-primary;
	    }
	    span{
	    	margin-right: 3px;
    		vertical-align: middle;
    		
    		@media screen and (min-width: 320px) {
    			font-size: 30px;
    		}
    		@media screen and (min-width: 768px) {
    			font-size: 25px;
    		}
	    }
	    span.text{
	    	font-size: 13px;
	    	@media screen and (min-width: 320px) {
	    		display:none;
	    	}
	    	@media screen and (min-width: 480px) {
	    		display:block;
	    	}
	    	@media screen and (min-width: 768px) {
	    		display:inline-block;
	    	}
	    }
	}
	li{
		width:25%;
		float:left !important;
	}
}

.discussion-list{
	a{
		display:block;
	}
	.avata{
		width: 30px;
    	height: 30px;
	}
	.media-body{
		h4{
			margin:0;
			line-height: 12px;
    		margin-bottom: 5px;
    		a{
				color: #333;
			    font-size: 12px;
			    display: inline-block;
			    vertical-align: top;
			    font-weight: bold;
			    &:hover{
			    	color:$brand-primary;
			    }
			}
    		.time{
				color: #999999;
			    font-size: 12px;
			    margin-bottom: 5px;
			    vertical-align: top;
			    margin-left: 5px;
			    margin-right: 5px;
			}
			.connect{
				color: #999999;
			    font-size: 12px;
			    margin-top: 5px;
			    vertical-align: top;
			    i{
			    	font-size: 9px;
    				color: #00a888;
			    }
			}
			.no-connect{
				color: #999999;
				font-size: 12px;
				margin-top: 5px;
				vertical-align: top;
				i{
					font-size: 9px;
					color: #999999;
				}
			}
		}
		h6{
			font-size: 16px;
		    margin: 0;
		    line-height: 20px;
		    margin-bottom: 5px;
		    color:#999;
		}
		.content,.activity{
			margin-bottom: 10px;
		}
		.content{
			font-size: 12px;
		}
		.activity{
			font-size: 12px;
    		color: #999;
    		span.like,span.report,span.reply{
    			display:inline-block;
    			cursor:pointer;
    			i{
    				color: #87a6bc;
    			}
    			&:hover,
    			&:hover i{
    				color:#333;
    			}
    		}
    		span.report_active{
    			i,&:hover i{
    				color:#a94442;
    			}
    		}
    		span.count,span.reply{
    			margin-right: 20px;
    		}
		}
		form{
			input,textarea,button{
				font-size: 12px;
			}
		}
	}
}

.unibee-model{
	.modal-title{
		color:$brand-primary;
		font-size: 17px;
		text-transform: uppercase;
	}
	.help-block{
		font-size: 11px;
		color:#999999;
		margin:0;
		i{
			color: #87a6bc;
		    font-size: 5px;
		    vertical-align: middle;
		    padding-right: 5px;
		    line-height: 0;
		}
	}
	label.title{
		font-size: 15px;
		font-family: arial;
		font-weight: 400;
	}
	.close{
		display:block;
		margin-top: 0;
		font-size: 20px;
		text-shadow: 0 0 0 #ccc;
		color:#fff;
		cursor:default;
		span{
			&:hover{
				color:#ddd;
				cursor:pointer;
			}
			
		}
	}
	.triangle-topright {
	    width: 0;
	    height: 0;
	    border-top: 40px solid $brand-primary;
	    border-left: 40px solid transparent;
	    position: absolute;
	    top: 0px;
	    right: 0;
	    opacity: 1;

	    span{
	    	position: relative;
		    top: -38px;
		    right: 16px;
	    }
	}
}

.unibee-rating {
	width:100%;
	display: block;

	section{
		float: left;
	}
	input[type="radio"]{
		display:none;
	}
	label.star {
		float: right;
		padding: 5px;
		font-size: 30px;
		color: #e4e4e4;
		transition: all .2s;
		font-weight: 400;
		line-height: 30px;
		cursor:pointer;
	   
	   &:hover{
	   	transform: rotate(-15deg) scale(1.3);
	   	color:#ffbb11;
	   }

		&:hover ~ label.star:before{
			color:#ffbb11;
			content: '\f005';
		}

		&:hover:before{
			content: '\f005';
		}

	}
	label.star:before {
		content: '\f006';
		font-family: FontAwesome;
	}
	.starRating{
		&:not(:hover){
			input.star:checked ~ label.star:before {
				content: '\f005';
				color: #FD4;
				transition: all .25s;
			}
			input.star-5:checked ~ label.star:before {
				color: #FE7;
				text-shadow: 0 0 20px #952;
			}
			input.star-1:checked ~ label.star:before { 
				color: #F62; 
			}
		}
	}
	.note_rating{
		line-height: 46px;
    	padding: 5px;
    	color:$brand-primary;
    	font-size: 12px;
	}
	
}

.unibee-box{
	margin:0;
	border:none;
	box-shadow: none;

	.panel-heading{
	    border-radius: 0;
	    padding: 0;
	    border-bottom: 0;
	    font-size: 20px;
    	color: #666;

    	h3{
    		font-size: 20px;
    		color: #666;
    		margin-bottom: 10px;
    	}
	}
}

.panel-recharge{
  margin-bottom: 0;
  .panel-heading{
	h3{
	  margin: 10px 0 0;
	}
  }
  .panel-body{
	padding-top: 5px;
  }
}

.tab_search{
	text-align: center;
	margin-bottom: 40px;

	li{
		display:inline-block;
		a{
			color:#999;
			padding-bottom: 10px;
			
			&:hover{
				color:#333;
			}
		}
	}
	li.active{
		a{
			border-bottom:2px solid $brand-primary;
		}
	}
	li:last-child{
		border-right: none;
	}

	@media only screen and (min-width : 320px) {
		li{
			padding: 0px 15px;
			a{
				font-size: 16px;
			}
		}
	}
	@media only screen and (min-width : 480px) {
		li{
			padding: 0px 30px;
			border-right: 1px solid #cccccc;
			a{
				font-size: 25px;
			}
		}
	}

}

#recharge_popup_form,#recharge_form{
  .account-box{
	background: #7595AD;
	padding: 10px;
	margin: 0 10px;
	color: #fff;
	a,.nn{
	  color: #fff;
	}
	h6{
	  line-height: 35px;
	  font-size: 18px;
	  .small{
		color: #fff;
	  }
	  margin: 0;
	  padding: 0;
	}
	.tiny-profile{
	  .avatar{
		float: left;
		height: 35px;
		width: 35px;
		margin-right: 5px;
	  }
	  .info{
		margin: 0;
		.name{
		  font-weight: bold;
		  line-height: 20px;
		}
		.email{
		  font-size: 90%;
		  line-height: 14px;
		}
	  }
	}

  }
  .nav-payment-methods {
	border-bottom: transparent;
	> li {
	  float: left;
	  // Make the list-items overlay the bottom border
	  margin-bottom: -1px;
	  width: 125px;
	  margin: 0 5px;
	  border: 1px solid #f1f1f1;
	  border-radius: $border-radius-small;

	  // Actual tabs (as links)
	  > a {
		&.by-bank-vnpay{
			background: url("../img/methods/vnpay_card.png") no-repeat center 4px  #fff;
			color: #86a6bb;
		}
		&.by-mobile-card{
		  background: url("../img/methods/mobile_card.png") no-repeat center 10px #fff;
		}
		&.by-bank-card{
		  background: url("../img/methods/bank_card.png") no-repeat center 10px #fff;
		}
		&.by-bank-exchange{
		  background: url("../img/methods/bank_exchange.png") no-repeat center 10px #fff;
		}
		border-radius: $border-radius-small;
		padding: 50px 10px 10px 10px;
		text-align: center;
		color: #86a6bb;
		&:hover {
		  color: #fff;
		  &.by-bank-vnpay{
			background: url("../img/methods/vnpay_card_active.PNG")  center 4px;
		   }
		  &.by-mobile-card{
			background: url("../img/methods/mobile_card_active.png") no-repeat center 10px $brand-primary;
		  }
		  &.by-bank-card{
			background: url("../img/methods/bank_card_active.png") no-repeat center 10px $brand-primary;
		  }
		  &.by-bank-exchange{
			background: url("../img/methods/bank_exchange_active.png") no-repeat center 10px $brand-primary;
		  }

		}
	  }

	  // Active state, and its :hover to override normal :hover
	  &.active > a {
		&,
		&:hover,
		&:focus {
		  color: #fff;
		  &.by-bank-vnpay{
			background: url("../img/methods/vnpay_card_active.PNG")  center 4px;
		   }
		  &.by-mobile-card{
			background: url("../img/methods/mobile_card_active.png") no-repeat center 10px $brand-primary;
		  }
		  &.by-bank-card{
			background: url("../img/methods/bank_card_active.png") no-repeat center 10px $brand-primary;
		  }
		  &.by-bank-exchange{
			background: url("../img/methods/bank_exchange_active.png") no-repeat center 10px $brand-primary;
		  }
		  cursor: default;
		}
		&::after{
		  content: "";
		  width: 0px;
		  height: 0px;
		  border-left: 10px solid transparent;
		  border-right: 10px solid transparent;
		  border-top: 10px solid $brand-primary;
		  position: absolute;
		  bottom: -10px;
		  left: 10px;

		}
	  }
	}
	// pulling this in mainly for less shorthand
	&.nav-justified {
	  @extend .nav-justified;
	  @extend .nav-tabs-justified;
	}
  }

  h4.recharge-method-title{
	padding: 20px 0 10px 0;
	border-bottom: 1px solid #dddddd;
	color: #86a6bb;
	font-size: 20px;
	margin-bottom: 20px;
  }
  .form-card-providers{
	label{
	  display: inline-block;
	  padding: 0 10px;
	  cursor: pointer;
	  text-align: center;
	  img{
		display: block;
	  }
	}
  }

  .recharge_methods_tabs{
	padding-top: 10px;
  }

  .recharge_notice{
	img{
	  margin-top: 50px;
	}
	ul{
	  padding:0;
	  margin: 0;
	  list-style: none;
	  li::before{
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		content: '\f111';
		//i.bullet{
		  color: #86a6bb;
		  font-size: 6px;
		  vertical-align: middle;
		//}
	  }
	}
  }

	.package-title{
		text-transform: uppercase;
		font-size: 13px;
		font-weight: bold;
	}

	.package-price{
		color: #00aa55;
		font-size: 15px;
		font-weight: bold;
		margin-left: 40px;
	}

	.radio-package{

	}

	.tab-chose-package{
		li {
			a{
				background: #f2f2f2;
				color: #999
			}
		}
		.active{
			a{
				background: #fff;
				color: #000;
			}
		}
	}
}

.style-info{
	a{
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}
	i{
		color: #87a6bc;
    	margin-right: 5px;
	}
	&:hover i{
		color:#fff;
	}
}

.note-pay{
	color: $brand-primary;
}

.gen-star{
	margin-bottom: 5px;

	.avg-star{
		color: #999;
		margin-left: 10px;
		font-size: 14px;
		line-height: 28px;
	}

	.num-vote{
		color: #999;
		margin-left: 10px;
	}
}

.stars{
	float:left;
	position:relative;
	min-height: 16px;
	margin: 3px 10px 0;

	.fa-star{
		&:before{
			content: "\f005\f005\f005\f005\f005";
			letter-spacing: 5px;
		}
		position: absolute;
		top:0;
		left:0;
	}

	.star-on{
		color: #fa0;
		z-index: 1;
		overflow:hidden;
	}

	.star-off{
		color: #e4e4e4;
		z-index: 0;
	}

	.stars-content{
		width: 110px;
		font-size: 20px;
	}
}

.twitter-typeahead{
	display: inline !important;
}

.list-lecture-item-success{
	border: 1px solid #ccc;
	border-top: 0;
}

.mu-service-area{
	display: inline;
	float: left;
	width: 100%;

	.mu-service-single{
		background: #bf845c;
		color: #fff;
		display: inline;
		float: left;

		@media only screen and (min-width : 320px) {
			width: 100%;
			height: 230px;
			padding: 20px 15px;
		}

		@media only screen and (min-width : 768px) {
			width: 30%;
			height: 280px;
			padding: 35px 25px;
		}

		h3,h4{
			text-transform: uppercase;
			margin: 0;
			margin-bottom: 7px;
		}

		h3{
			font-size: 15px;
			line-height: 15px;
			font-family: "Montserrat", sans-serif;
		}
		h4{
			font-size: 22px;
			line-height: 22px;
			font-weight: bold;
		}
		p{
			font-weight: lighter;
			font-family: "Roboto", sans-serif;
			line-height: 18px;
			margin: 0;
		}

		.btn-more{
			background: url("../img/e-guide/i_button.png") no-repeat;
			background-size: cover;
			width: 151px;
			height: 40px;
			display: block;

			@media only screen and (min-width : 320px) {
				margin-top: 10px;
			}

			@media only screen and (min-width : 768px) {
				margin-top: 30px;
			}
		}
	}

	.mu-service-single-guide{
		.btn-more{
			@media only screen and (min-width : 320px) {
				float: right;
			}

			@media only screen and (min-width : 768px) {
				float: left;
			}
		}
	}

	.mu-service-single-other{
		.btn-more{
			@media only screen and (min-width : 320px) {
				float: right;
			}
		}
	}

	.mu-service-single-system{
		background: url("../img/e-guide/quy_trinh.png") no-repeat;
		background-size: cover;
		position: relative;

		@media only screen and (min-width : 320px) {
			width: 100%;
			padding: 20px 15px;
		}

		@media only screen and (min-width : 768px) {
			width: 40%;
			padding: 35px 55px;
		}

		.btn-more{
			@media only screen and (min-width : 320px) {
				float: right;
			}

			@media only screen and (min-width : 768px) {
				margin: 30 auto;
				float: none;
			}
		}
	}

	.mu-service-single-system,
	.mu-service-single-other{
		.wrap-icon{

			position: relative;
			width: 60px;
			margin-left: auto;
			margin-right: auto;
			height: 60px;
			display: block;
			border-radius: 50%;

			@media only screen and (min-width : 320px) {
				display: block;
			}
			@media only screen and (min-width : 768px) {
				display: none;
			}
		}
	}

	.mu-service-single-system{
		.wrap-icon{
			background-color: #d2d3d5;
		}
	}

	.mu-service-single-other{
		.wrap-icon{
			background-color: #d2d3d5;
		}
	}

	.triangle-bottom-left,
	.triangle-bottom-right{
		position: absolute;

		@media only screen and (min-width : 320px) {
			display: none;
		}

		@media only screen and (min-width : 768px) {
			display: block;
		}
	}

	.triangle-bottom-left {
		width: 0;
		height: 0;
		border-bottom: 280px solid #bf845c;
		border-right: 60px solid transparent;
		left: 0;
		top: 0;
	}

	.triangle-bottom-right {
		width: 0;
		height: 0;
		border-bottom: 280px solid #bf845c;
		border-left: 60px solid transparent;
		right: 0;
		top: 0;
	}
}

.mu-test{
	background: url("../img/e-guide/bg-test-title.png") no-repeat;
	background-size: cover;
	width: 100%;

	@media only screen and (min-width : 320px) {
		border-top: 8px solid #f87831;
		border-bottom: 8px solid #f87831;
	}

	@media only screen and (min-width : 768px) {
		border-top: 0;
		border-bottom: 0;
	}

	h3{
		a{
			font-weight: bold;
			text-transform: uppercase;
			color: #fff;
		}
	}

	@media only screen and (min-width : 320px) {
		height: 100px;
		h3{
			margin: 34px;
			text-align: center;
			font-size: 20px;
		}
	}

	@media only screen and (min-width : 768px) {
		height: 140px;
		h3{
			margin: 54px 0 54px 50px;
			text-align: left;
			font-size: 30px;
		}
	}

	.wrap-icon {
		position: relative;
		width: 80px;
		margin-left: auto;
		margin-right: auto;
		height: 80px;
		display: block;
		border-radius: 50%;
		margin-top: 170px;
		margin-bottom: 30px;
		background-color: #d2d3d5;

		@media only screen and (min-width : 320px) {
			display: none;
		}

		@media only screen and (min-width : 768px) {
			display: block;
		}
	}
}

.box-modun{
	background: #d2d3d5;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	z-index: 1;
	position: relative;

	@media only screen and (min-width : 320px) {
		padding: 15px;
	}

	@media only screen and (min-width : 768px) {
		padding: 30px;
	}

	.media{
		font-size: 22px;
		color: #fff;
		text-transform: uppercase;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 4px;

		.icon-guide{
			background-size: cover;
			margin: 35px 30px;
		}

		@media only screen and (min-width : 320px) {
			margin-bottom: 15px;
			height: 120px;
			.icon-guide{
				height: 50px;
				width: 55px;
			}

			.media-heading{
				font-size: 20px;
				color: #fff;
			}
		}

		@media only screen and (min-width : 768px) {
			margin-bottom: 30px;
			height: 140px;
			.icon-guide{
				height: 70px;
				width: 78px;
			}

			.media-heading{
				font-size: 30px;
			}
		}

		.icon-trend{
			background-image: url("../img/e-guide/i_trend.png");
		}

		.icon-course{
			background-image: url("../img/e-guide/i_course.png");
		}

		.icon-career{
			background-image: url("../img/e-guide/i_career.png");
		}

		.icon-unit{
			background-image: url("../img/e-guide/i_unit.png");
		}

		.icon-business{
			background-image: url("../img/e-guide/i_business.png");
		}

		.icon-famous{
			background-image: url("../img/e-guide/i_famous.png");
		}
	}

	.media-trend{
		background-image: url("../img/e-guide/trend.png");
	}

	.media-guide-course{
		background-image: url("../img/e-guide/course.png");
	}

	.media-career{
		background-image: url("../img/e-guide/career.png");
	}

	.media-unit{
		background-image: url("../img/e-guide/unit.png");
	}

	.media-business{
		background-image: url("../img/e-guide/business.png");

		@media only screen and (min-width : 320px) {
			margin-bottom: 15px;
		}
		@media only screen and (min-width : 768px) {
			margin-bottom: 0;
		}
	}

	.media-famous{
		background-image: url("../img/e-guide/famous.png");
		margin-bottom: 0;
	}
}

#footer-e-guide{
	background-image: url("../img/e-guide/footer.jpg");
	background-size: cover;
	bottom: 0;
	width: 100%;
	margin-top: -60px;
	z-index: 0;

	.overlay-footer{
		background: rgba(0, 0, 0, 0.7);
		padding: 120px 0 20px 0;
		width: 100%;
	}

	.item-footer{
		border: 2px solid #d66f2d;
	}

	.item-footer-social{
		background: #dbd9da;
		padding: 35px 0;
		text-align: center;
		border-radius: 3px;
	}

	.item-form{
		background: #d56b37;
		padding: 21px 20px;
		border-radius: 3px;
		width: 100%;
		float: left;

		@media only screen and (min-width : 320px) {
			overflow: hidden;
			position: relative;
			z-index: 999;
			margin-bottom: 15px;
		}

		@media only screen and (min-width : 768px) {
			margin-bottom: 0;
		}

		h4{
			text-align: center;
			margin: 0;
			margin-bottom: 10px;
			color: #fff;
			text-transform: uppercase;
		}

		.c_input{
			width: 70%;
			float: left;
			input {
				border: 0;
				box-shadow: none;
				outline: none;
				border-radius: 0;
				margin-bottom: 2px;
				height:33px;
			}
		}

		.c_button{
			width: 30%;
			float: left;
			button{
				border: 0;
				box-shadow: none;
				outline: none;
				height: 103px;
				border-radius: 0;
				border-left: 1px solid #d56b37;
				background: #fff;

				span{
					color: #d56b37;
					font-size: 30px;
				}
			}
		}
	}

	.item-footer-maps{
		position: relative;
		overflow: hidden;
		@media only screen and (min-width : 320px) {
			margin-bottom: 15px;
		}

		@media only screen and (min-width : 768px) {
			margin-bottom: 0;
		}
	}

	.social-facebook,
	.social-google{
		width: 100px;
		height: 100px;
		display: inline-block;
		background-size: cover;
		margin: 0 25px;
		border-radius: 4px;;
	}

	.social-facebook{
		background-image: url("../img/e-guide/i_facebook.png");
	}

	.social-google{
		background-image: url("../img/e-guide/i_youtube.png");
	}

	.media-address{
		font-size: 22px;
		color: #fff;
		margin-bottom: 20px;
		@media only screen and (max-width : 520px) {
			margin-bottom: 5px;
		}
		.icon-guide{
			height: 25px;
			width: 25px;
			background-size: cover;
			margin: 0 10px;
		}

		h4,p{
			color: #fff;
		}

		h4{
			font-size: 16px;
		}

		p{
			font-size: 14px;
		}

	}

	.icon-map{
		background-image: url("../img/e-guide/i_map.png");
	}

	.icon-call{
		background-image: url("../img/e-guide/i_call.png");
	}

	.icon-email{
		background-image: url("../img/e-guide/i_email.png");
	}

	.box-menu-foot{
		@media only screen and (max-width : 520px) {
			margin-top: 15px;
		}
		h3{
			font-size: 20px;
			text-transform: uppercase;
			color: #fff;
			margin-left: 30px;
		}
		.menu-foot{
			padding: 0;
			list-style-type: none;
			margin: 0;
			li{
				width: 50%;
				float: left;
				display: inline-block;
				a{
					color: #fff;
					font-size: 14px;
					padding: 5px 30px;
					display: block;
					.glyphicon{
						font-size: 11px;
					}
				}
			}
		}
	}
}

.footer-bot{
	background: #132D36;
	text-align: center;
	line-height: 45px;
	height: 45px;
	color: #fff;
}

.box-foot-address{
	margin: 30px 0;
}

.container-cover{
	background-image: url("../img/e-guide/bg-head.jpg");
	width: 100%;
	background-size: cover;

	@media only screen and (min-width : 320px) {
		height: 300px;
	}

	@media only screen and (min-width : 768px) {
		height: 500px;
	}

	.container-overlay{
		background: rgba(101, 101, 101, 0.4);
		width: 100%;
		height: 100%;
	}

	.big-title{

		@media only screen and (min-width : 320px) {
			margin-top: 170px;

			.medium-intro{
				font-size: 18px;
				margin: 0;
			}
			.big-intro{
				font-size: 32px;
				margin: 0;
				line-height: 40px;
			}
			.small-intro{
				font-size: 12px;
				margin: 0;
			}
		}

		@media only screen and (min-width : 768px) {
			margin-top: 200px;
			.medium-intro{
				font-size: 34px;
				margin: 0;
			}
			.big-intro{
				font-size: 60px;
				margin: 0;
				line-height: 60px;
			}
			.small-intro{
				font-size: 23px;
				margin: 0;
			}
		}

		color: #ffff;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.static-body{
	background: #fff url("../img/e-guide/backdrop_static.png");
	background-size: contain;
	margin: 40px 0;
	border-radius: 4px 4px 0 0;

	.content-body{
		text-align: justify;

		.wrap-title{
			margin-bottom: 20px;
		}

		.video{
			margin-bottom: 10px;
		}

		.title,
		.sub_title{
			text-align: center;
			font-weight: bold;
		}

		.title{
			font-size: 30px;
			color: #f37435;
		}

		.sub_title{
			color: #1b344d;
			padding-top: 10px;
			font-size: 35px;
		}
		iframe{
			max-width: 100%;
		}
	}
}

.static-body-guide{
	background: #ffff url("../img/novai/static/bg.png");
	background-size: cover;
	margin-top: 30px;
	margin-bottom: 40px;
	border-radius: 4px 4px 0 0;

	.content-body{
		text-align: justify;

		.top-body-guide{
			background: #1b334d;
			color: #fff;
			@media only screen and (min-width : 320px) {
				padding: 20px 20px;
				padding-bottom: 170px;
			}
			@media only screen and (min-width : 768px) {
				padding: 20px 50px;
				padding-bottom: 170px;
			}
		}

		.wrap-title{
			margin-bottom: 20px;
		}

		.video{
			margin-bottom: 10px;
			margin-top: -150px;
		}

		.title,
		.sub_title{
			text-align: center;
		}

		.title{
			font-size: 25px;
			color: #f37935;
			margin-bottom: 10px;
			font-weight: bold;
		}

		.sub_title{
			font-size: 35px;
		}

		.sub_title_2{
			margin: 20px 0;
		}

		iframe{
			max-width: 100%;
		}
	}

	.box-line-wrap{
		text-align: justify;
		margin-bottom: 50px;
		img{
			padding: 0px;
			margin: 0px;
		}
	}

	.line-box-guide{
		text-align: center;
		background: #1b334d;
		color: #fff;
		padding: 10px;
		text-transform: uppercase;
		font-size: 18px;
		margin: 20px 0 20px 0;
	}

	.box-gray-wrap{
		background: #e5e5e5;
		padding: 20px 0 50px 0;
		margin-bottom: 40px;
		h4{
			color: #1a344d;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;

			@media only screen and (min-width : 320px) {
				margin: 10px 0 20px 0;
			}

			@media only screen and (min-width : 768px) {
				margin: 10px 0 50px 0;
			}
		}
		p{
			text-align: justify;
			@media only screen and (min-width : 320px) {
				padding: 15px;
			}
			@media only screen and (min-width : 768px) {
				padding: 0;
			}
		}
		img{
			margin: 15px;
			margin-right: 0;
			margin-top: 0;

			@media only screen and (min-width : 320px) {
				width: 100%;
			}

			@media only screen and (min-width : 768px) {
				width: auto;
			}
		}
	}

	.box-mission{
		h4{
			color: #1a344d;
			font-weight: bold;
		}
		p{
			text-align: justify;
		}
	}

	.box_trl{
		margin: 30px 0 40px 0;
	}

	.view_btn_process{
		padding: 0px 0 20px 0;
		margin: 40px 0;
		@media only screen and (min-width : 320px) {
			text-align: center;
		}

		@media only screen and (min-width : 768px) {
			text-align: right;
		}
	}

	.col-md-4{
		padding-right: 15px !important;
	}
}

.avata-login{
  width: 60px;
  background: transparent;
  border: 0;
}

.notification{
  margin: 18px;
  color: #fff;
}

.box-action-test{
	text-align: center;
	background: #fff;
	margin: 40px 0 100px 0;
	border-radius: 4px;
	padding: 20px 0;

	h3.title{
		font-size: 35px;
		text-transform: uppercase;
		font-weight: bold;
		.key-title{
			color: #f37935;
		}
	}
	
	h5.sub-title{
		font-size: 14px;
		text-transform: uppercase;
	}

	.test-warning{
		text-align: center;
		background: #7cc150;
		padding: 5px;
		margin: 30px 0;
		.key-title{
			color: #f37935;
			font-weight: bold;
		}
	}
}

.btn-normal{
	background: #f58634;
	background-repeat: no-repeat;
	color: fff;
}

.fixclass-bottom{
	position: fixed;
	bottom: 10px;
	left: 20px;
	z-index: 999;
	@media only screen and (max-width : 480px) {
		display: none;
	}
	.bubble-close {
		background: url(../img/e-guide/close.png) no-repeat scroll center center;
		height: 30px;
		padding: 5px;
		position: absolute;
		right: -5px;
		top: -15px;
		width: 30px;
		z-index: 9999;
	}
}

.control-panel{
	position: relative;
	top: 21px;
	right: 10px;
	z-index:99;
   a{
	   color: #fff;
   }
	a:hover{
		color: #ff9900;
	}
}
.nav > li > a{
	padding: 10px 8px;
}
.box-header{
	padding: 10px;
	font-size: 18px;
	font-weight: bold;
	color: #f58634;
}
.list_news {
	border-bottom: 1px dotted #e2e2e3;
	margin-bottom: 10px;
	padding-bottom: 10px;
	float: left;
	width: 100%;
	.title_news{
		height: 32px;
		overflow: hidden;
		display: inline-block;
		@media only screen and (max-width: 560px){
			height: auto;
		}
	}
	.title_news a{
		font: 700 14px arial;
		color: #333;
		position: relative;
		z-index: 3;
	}
	.thumb_art {
		position: relative;
		margin-right: 10px;
		float: left;
		width: 240px;
		margin-bottom: 10px;
	}
	.info-text{
		color: #7d7d7d;
		font-size: 12px;
	}
	.thumb_art img {
		width: 100%;
	}
	.description{
		height: 82px;
		overflow: hidden;
	}
	@media only screen and (max-width: 480px){
		.thumb_art{ width: 100%}
	}
}


#ask {
	background-color: #f8f8f8;
	.title_cauhoi{
		position: relative;
		z-index: 1;
		color: #ff6600;
		left: 50px;
		background-color: #fff;
		margin: 50px auto;
		padding: 30px;
		strong{
			font-size: 64px;
			font-family: Tahoma;
		}
		form {
			padding: 40px;
			input{
				width: 100%;
				border-radius: 30px;
				border: none;
				background-color: #e6e6e6;
				outline: none;
				padding: 5px 0px 5px 25px;
			}
		}

		#cauhoi {
			border-top: 2px solid #ffc39b;
			border-bottom: 2px solid #ffc39b;
			.cauhoi {
				background-color: #fff;

				.accordion {
					background-color: #fff;
					cursor: pointer;
					padding: 10px;
					width: 100%;
					text-align: left;
					color: white;
					border: none;
					outline: none;
					transition: 0.4s;

					h5 {
						color: #ff6600;

						.pheptinh {
							float: right;
							position: relative;
						}
					}
				}
				.panel {
					color: #504f4e;
					padding: 0 18px;
					background-color: white;
					display: none;
					overflow: hidden;
				}
			}
		}

		.sendask {
			margin: 60px;
			a{
				background-color: #ff6600;
				color: #fff;
				border-radius: 20px;
				padding: 10px 100px;
			}
		}

		.hoi {
			overflow: hidden;
			img {
				margin-top: 50px;
			}
		}

	}
}

.accordion:hover {
	background-color: #ccc;
}

@media screen and (max-width: 564px) {
	#ask .title_cauhoi{
		left: 0px;
		strong{
			font-size: 44px
		}
	}
	.sendask a{padding: 10px 45px !important;}
	.hoi img {
		display: none;
	}
	.mini-pic{
		width: 38px;
		height: 38px;
		margin-top: 5px;
	}
}
.show-more{
	display: none;
	color: #f58634;
	cursor: pointer;
	font-weight: bold;
	text-align: center;
	margin-bottom: 10px;
	text-decoration: underline;
	font-size: 17px;
}
.btn-join{
	background: transparent !important;
	color: #171515;
	font-weight: bold;
	border: 1px solid #171515 !important;
	border-radius: 5px;
}
.btn-join:hover{
	color:#f37435;
}

/*Trang gioi thieu & 1 phan trang chu*/
#system {
	background-color: #fbfbfb;
	color: #1b344d;
	text-align: center;
}

#system .system .system_heading{
	width: 30%;
	padding-top: 20px;
	padding-bottom: 20px;
	margin: auto;
}

#system .system h1{
	font-family: Bold;
}

#system .system p {
	width: 60%;
	margin: auto;
	text-align: center;
	font-weight: bold;
}

#system .system .container {
	margin-top: 60px;
}

#system .system .col {
	color: #2e455c;
	margin-bottom: 50px;
	background-color: #fff;
	padding: 1px;
	border-top: 2px solid #1b344d;
}

#system .system .col:hover {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-top: 2px solid #f37435;
}

#system .system .col:hover h4{
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	color: #f37435;
}
#system .system .col:hover .khu {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border: 2px solid #f37435;
}

#system .system .col:hover .button {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	color: #f37435;
	border: 1px solid #f37435;
}

#system .system .col p{
	height: 80px;
	margin-bottom: 30px;
	width: 85%;
	font-weight: bold;
	margin: auto;
	text-align: justify;
}

#system .system .col .khu{
	border: 2px solid #1b344d;
	width: 25%;
	border-radius: 10px;
	background-color: #fbfbfb;
	z-index: 1;
	width: 50px;
	height: 50px;
	margin: auto;
	-ms-transform: rotate(45deg); /* IE 9 */
	-ms-transform-origin: 100% 100%; /* IE 9 */
	transform: rotate(45deg);
	transform-origin:  116% 34%;
}

#system .system .col h4{
	height: 30px;
	margin-top: 0px;
	font-family: Bold;
	color: #f37435;
}

#system .system .col .ronate{
	position: relative;
}

#system .system .col .ronate .bong{
	opacity: 0.4;
}

#system .system .col .ronate img{
	position: absolute;
	top: -16px;
	right: 43%;
	font-size: 27px;
}

#system .system .col .intro{
	margin: 20px;
}

#system .system .col .intro a{
	border-radius: 12px;
	padding: 6px 12px;
	color: #1b344d;
	border: 1px solid #1b344d;
	font-family: ExtraBoldItalic;
}


@media (max-width: 766px) {

	#system .system .system_heading{
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		margin: auto;
	}

	#system .system p {
		width: 90%;
		text-align: justify;
	}
	#system .system .col .ronate img{
		right: 44%;
	}
}



/*gioithieu.html*/

#play_system {
	color: #1b344d;
}

#play_system .play_system {
	width: 96%;
	margin: auto;
	border-top: 3px solid #1b344d;
}

#play_system .play_system .khu{
	border: 2px solid #1b344d;
	width: 25%;
	border-radius: 10px;
	background-color: #fbfbfb;
	z-index: 1;
	width: 50px;
	height: 50px;
	margin: auto;
	-ms-transform: rotate(45deg); /* IE 9 */
	-ms-transform-origin: 100% 100%; /* IE 9 */
	transform: rotate(45deg);
	transform-origin:  116% 34%;
}

#play_system .play_system .ronate{
	position: relative;
}

#play_system .play_system .ronate img{
	position: absolute;
	top: -16px;
	right: 48.1%;
	font-size: 27px;
}
@media (max-width: 766px) {
	#play_system .play_system .ronate img{
		right: 44%;
	}
}

#play_system .play_system .play_system_heading h1{
	text-align: center;
	font-family: Bold;
	padding-bottom: 20px;
}

#play_system .play_system .play_system_heading img{
	opacity: 0.6;
}

#play_system .play_system .view {
	display: flex;
}

#play_system .play_system .view .img{
	width: 35%;
}

#play_system .play_system .view .link{
	width: 65%;
	position: relative;
}

#play_system .play_system .view .link{
	width: 65%;
	position: relative;
}

#play_system .play_system .view .link .link_title{
	position: absolute;
	top: 40%;
	left: 20%;
	text-align: center;
}

#play_system .play_system .view .link .link_title h4{
	font-family: Bold;
	padding-bottom: 10px;
}

#play_system .play_system .view .link .link_title a{
	border-radius: 12px;
	padding: 6px 12px;
	color: #1b344d;
	border: 1px solid #1b344d;
	font-family: ExtraBoldItalic;
}

#play_system .play_system .view:hover i,#play_system .play_system .view:hover h4{
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	cursor: pointer;
	transition: 0.5s;
	color: #f37435;
}

#play_system .play_system .view:hover .link .link_title a{
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border: 1px solid #f37435;
}
.scorll-height{
	max-height: 300px;
	overflow: scroll;
}


/*packageRegistration*/
#packageRegistration{
	padding: 0 20px;
	font-size: 12px;
}

#packageRegistration .heading{
	text-align: center;
}
.red-tooltip + .tooltip > .tooltip-inner {background-color: #ff9900 !important;}
#packageRegistration .heading h1{
	font-family: Bold;
	padding-top: 20px;
	color: #f37435;
	height: 50px;
}
#packageRegistration .heading h4{
	font-family: Bold;
	font-size: 26px;
	color: #1b344d;
}
#packageRegistration .heading p{
	font-family: Medium;
	font-weight: 600;
}
.box-info-hotro{
  padding: 10px 20px;
  background-color: #0a4e8e;
  color: #fff;
  border-radius: 5px;
  font-size: 19px;
	text-align: justify;
}
.info-package-des{
  text-align: left;
}
.info-package-des .text-des{
  padding-bottom: 10px;
	font-size: 13px;
	text-align: justify;
	padding-left:50px;
}
.info-vip {
	display: inline-grid;
}
.info-package-des p i{
  color: #f37435;
}
.info-vip h3{
  padding-bottom: 20px;
  color: #1b344d;
  font-weight: bold;
  text-align: center;
  font-size: 21px;
	margin-top:36px
}
.hotro-pack{
	color: #ff0;
	font-size: 15px;
	text-transform: uppercase;
}
#packageRegistration .row{
	margin-top: 35px;
}

#packageRegistration .row .package{
	color: #ffffff;
	position: relative;
	margin-bottom: 20px;
	width: 94%;
	margin: auto;
	font-family: Medium;
	background-image: url("../img/novai/bannergia.jpg");
	background-size: cover;
}

#packageRegistration .row .package img{
	position: absolute;
	top: 0px;
	right: 0px;
}

#packageRegistration .row .package .name_package{
	position: relative;
	padding-top: 40px;
}
.img-info img{
	padding-right: 5px;
	padding-bottom: 5px;
}
#packageRegistration .row .package h1{
	font-family: Bold;
	height: 40px;
}

#packageRegistration .row .package h2{
	height: 55px;
	text-decoration: line-through;
}

#packageRegistration .row .package div{
	padding-top: 30px;
	padding-bottom: 16px;
}

#packageRegistration .row .package a{
	font-family: Medium;
	border-radius: 8px;
	padding: 6px 50px;
	color: #1b344d;
	background-color: #ffffff;
}

#packageRegistration .row .package .content{
	font-size: 10px;
	padding: 0 5px 10px 5px;
	margin-bottom: 30px;
}

#packageRegistration .row .package .name_package:before{
	content: '______';
	left: 42%;
	top: 75%;
	position: absolute;
}

#packageRegistration .row .package:hover{
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	transform: scale(1.15);
}

#packageRegistration table {
	margin-bottom: 20px;
}

#packageRegistration table th[scope="col"]{
	color: #ffffff;
	font-family: Medium;
	text-align: center;
	border-right: 1px solid #808080;
	background-color: #1b344d;
}

#packageRegistration table th[scope="row"]{
	border-right: 1px solid #808080;
	border-left: 1px solid #808080;
	color: #808080;
	padding: 0 8px;
}

#packageRegistration table tr td{
	text-align: center;
	border-right: 1px solid #808080;
}

#packageRegistration table tr:last-child{
	border: 1px solid #808080;
}

#packageRegistration table tr:last-child td, #packageRegistration table tr:last-child .luuy {
	color: #fd2a2a;
}

#packageRegistration table tr:last-child .luuy {
	font-size: 14px;
	padding: 8px;
}

#packageRegistration table .fa-check {
	font-size: 23px;
	color: #058f34;
}

#packageRegistration table .fa-close {
	font-size: 23px;
	color: #fd2a2a;
}

#packageRegistration table span {
	font-size: 18px;
	padding-left: 10px;
	font-family: Medium;
}

#packageRegistration table tr th div{
	margin-top: 10px;
}

#packageRegistration table tr th div span{
	background-color: #ffffff;
	padding: 3px 10px;
	color: #000000;
}
#packageRegistration .buynow{
	padding-top: 20px;
  }
#packageRegistration .buynow a{
	font-family: Medium;
	border-radius: 8px;
	padding: 6px 50px;
	color: #ffffff;
	-webkit-box-shadow: 2px 5px 6px -2px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 5px 6px -2px rgba(0,0,0,0.75);
	box-shadow: 2px 5px 6px -2px rgba(0,0,0,0.75);
	background-color: #f37435;
}

#packageRegistration .buynow a:hover{
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	color: #ffffff;
	background-color: #1b344d;
}

#packageRegistration .goiy a{
	font-family: Medium;
	border-radius: 8px;
	padding: 6px;
	width: 100%;
	position: static;
	position: relative;
	color: #ffffff;
	background-color: #f37435;
}


#packageRegistration .goiy a:before{
	content: ' ';
	width: 0;
	position: absolute;
	bottom: -20px;
	left: 45%;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 20px solid #f37435;
}

#packageRegistration table {
	width: 100%;
	border-collapse: collapse;
}
#packageRegistration th {
	color: white;
	font-weight: bold;
}
#packageRegistration td, th {
	padding: 6px;
	border: 1px solid #ccc;
	text-align: left;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
	#packageRegistration .row .package h2{
		height: 28px;
		font-size: 20px;
		text-decoration: line-through;
	}
	#packageRegistration .row .package .name_package{
		padding-top: 8px;
	}
	#packageRegistration .row .package div{
		padding-top: 6px;
		padding-bottom: 10px;
	}
	.info-vip h3{
		margin-top: 0;
	}
	.box-info-hotro{
		font-size: 13px;
	}
	.info-package-des .text-des{
		padding: 0;
	}
	.img-info img{
		width: 50%;
		float: left;
		height: 95px;
		padding-right: 5px;
		padding-bottom: 5px;
	}
}

#packageRegistration .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
	 padding: 10px;
	line-height: 1.428571429;
	vertical-align: top;
	border-top: 1px solid #eeeeee;
}


/*Dùng thẻ div*/

.columnone {
	float: left;
	width: 16%;
}
.column{
	float: left;
	width: 14%;
}
.th{
	color: #ffffff;
	width: 100%;
	font-family: Medium;
	text-align: center;
	border-right: 1px solid #808080;
	background-color: #1b344d;
	height: 80px;
}

.th .title_th{
	padding: 15px;
}

.th .title_th div{
	padding: 10px 0;
}

.td {
	border-right: 1px solid gray;
	border-left: 1px solid gray;
	padding: 15px;
	height: 60px;
	font-family: Medium;
}

.td:last-child {
	border-top: 1px solid gray;
	border-bottom: 1px solid gray;
	font-size: 23px;
	padding: 40px;
	color: #fd2a2a;
	height: 134px;
	font-weight: bold;
}

.tr {
	border-right: 1px solid gray;
	padding: 10px;
	height: 60px;
	text-align: center;
	font-family: Medium;
}

.tr:last-child {
	border-top: 1px solid gray;
	border-bottom: 1px solid gray;
	color: #fd2a2a;
	height: 134px;
	font-size: 14px;
}



.th .title_th span{
	background-color: #ffffff;
	font-weight: bold;
	font-size: 18px;
	padding: 3px 10px;
	color: #000000;
}
/* Clear floats after the columns */
.row:after {
	content: "";
	display: table;
	clear: both;
}

.tr .fa-check {
	font-size: 23px;
	color: #058f34;
}

.tr .fa-close {
	font-size: 23px;
	color: #fd2a2a;
}

.tr span {
	font-size: 20px;
	padding-left: 10px;
	font-family: Medium;
	color: #000000;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	.td:last-child, .tr:last-child {
		margin-bottom: 20px;
		border-top: none;
	}

	.columnone {
		float: left;
		width: 50%;
	}
	.column{
		float: left;
		width: 50%;
	}

}

.naptien a{
	background-color: #f37435;
	padding: 7px;
	color: #fff;
	font-size: 11px;
	padding: 4px;
	border-radius: 0px;
}
.info-question{
	padding-left: 0;
	list-style:none;
	font-weight:normal;
	font-size:14px;
}
.info-question li{
	padding: 5px;
	border-bottom: 1px solid #ccc;
}
.button-show {
	border-radius: 12px;
	padding: 6px 12px;
	color: #1b344d;
	border: 1px solid #1b344d;
	font-family: ExtraBoldItalic;
}
.show-texttest{
	padding-top: 25px;

}
.ads-top{
	color: #fff;
}
.ads-top a{
	color: #ff9900 !important;
	text-decoration: underline;
}

.box_category_top img{
	width: 80px ;
}
.img-cup{
	padding-top: 25px;
}
.box_category_top{
	display: -webkit-inline-box;
	margin-top: 40px;
}
.box_category_top .top{
	padding-top: 15px;
}
.box_category_top .top p:nth-child(1){
	font-size: 23px !important;
	font-weight: bold !important;
	margin-bottom: -20px;
}
.box_category_top .top p{
	font-weight: 900;
	padding: 0px 22px;
	font-size: 24px ;
	margin: 0px ;
}
.row-table{
	.tr-rank td img{
		border-radius: 50% ;
		padding: 3px;
	}
	.tr-rank td {
		text-align: center;
		border: 3px solid #fff !important;
		line-height: 45px !important;
	}
	.tr-rank td:nth-child(1) {
		background: #EFBF75 ;
	}
	.table thead tr th{
		text-align: center;
		border: 3px solid #fff !important;
		padding: 10px 5px !important;
	}
	.table thead tr{
		background: #EFBF75 ;
	}
	.exam-1, .exam-2, .exam-3, .exam-4, .exam-5{
		background: #FFB3AB !important;
	}
	.exam-6, .exam-7, .exam-8, .exam-9, .exam-10{
		background: #FFE98D !important;
	}
	.exam-11, .exam-12, .exam-13, .exam-14, .exam-15{
		background: #DEF26B !important;
	}
	.exam-16, .exam-17, .exam-18, .exam-19, .exam-20{
		background: #5AB5F9 !important;
	}
	.table{
		font-size: 13px ;
	}
}
.row-top{
	text-align: center;
}
// kết quả bai test
.sw-container{
	.tab-exam-info>.row>.col-md-9{
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
		padding-bottom: 20px;
		.show-result .exam-title{
			margin-left: -15px;
            margin-right: -15px;
		}
	}
}

.row-results-test{
	// background: #EDEDED;
	padding-top: 10px;
	margin-top: 20px;
	 >.col-md-12 {
		 h3{
			color: #ff9900;
			font-weight: bold;
			text-transform: uppercase;
		 }
		 h2{
			font-style: italic;
			margin-bottom: 15px;
			color: #ff9900;
		 }
	 }
	 .col-text{
		margin-bottom: 10px;
		 h3{
			font-weight: bold;
			font-style: italic;
			color: #333;
			text-transform: none;
		 }
	 }
	 .col-img{
		  .text-center{
			    margin-top: 5px;
				p{
					margin: 0 0 0px;
					color: #ff7600;
					font-weight: bold;
					font-size: 19px;
					line-height: 21px;
				}
		  }
		  .col-hover{
            margin-top: 15px;
		}
		
		// hover
        .col-md-3:hover .overlay-rulest {
              height: 60%;
            }
        .overlay-rulest {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
			background-color: rgba(0, 0, 0, 0.83);
            overflow: hidden;
            width: 100%;
            height: 0;
            transition: .5s ease;
            }
        .text {
			line-height: 16px;
            width: 100%;
            text-align: justify !important;
			padding: 0px 12px;

            color: white;
            font-size: 13px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
			}
		// end hover
	 }
	 .col-sub{
		margin: 23px 0px;
		.sub{
			a{
				padding: 7px 30px;
				border-radius: 10px;
				font-size: 18px;
				font-weight: bold;
				font-style: italic;
			}  
			.a-1{
				color: #ff7600;
				border: 1px solid ;
				border-color: #ff7600;
				background: #fff;
				&:hover{
					background: #31b0d5;
					color: #fff;
					border-color: #31b0d5;
				}
			}
			.a-2{
				 background: #EA4E1B;
				 color: #fff ;
				 &:hover{
					background: #31b0d5;
					color: #fff;
					border-color: #31b0d5;
				}
			}
		}
	 }
}
.img img{
	width: 100% ;
}
.header-row-exam{
    margin-top: 20px;
    margin-bottom: 20px;
	p{
		color: #ff7600;
		font-weight: bold;
		font-size: 18px;
	}
}
.row-exam-other .col-md-5{
	img{
		border-radius: 15px;
		box-shadow: 0 0 15px rgba(0,0,0,0.15);
	}
	.title{
		margin-top: 15px;
		a{
			color: #ff7600 ;
			font-size: 16px;
			font-weight: bold;
			&:hover{
				color: #31b0d5;
			}
		}
		 p{
			font-weight: bold;
			margin: 0 0 0px;
			font-size: 16px;
		 }
	}
	.test-orther{
		display: none;
	}
}
.contentimg img{
	width: 100% !important ;
}
.contentimg .cke_widget_drag_handler{
	display: none !important;
}


.carousel_slider .owl-nav [class*="owl-"] {
	box-shadow: 0 0 8px rgba(0,0,0,0.15);
	color: #03c52d !important;
	font-size: 18px;
	margin: 0;
	padding: 6px 0px;
	background: #fff;
	border-radius: 0;
	position: absolute;
	top: 38%;
	-moz-transform: translateY(-50%);
	-webkittransform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 0.3s ease-in-out;
}
.carousel_slider .owl-nav .owl-prev {
	left: 0;
}
.carousel_slider .owl-nav .owl-next {
	right: 0;
}

// end kết quả bài test
.carousel-rulest .col-xs-12 .show-modal-review img {
    width: 150px !important;
    height: 150px !important;
    border-radius: 50%;
}
@media (max-width: 768px){
	.table-responsive{
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}
@media (max-width: 425px){
	.bookid-lesson .col-video img{
		width:50%;
	}
	.bookid-lesson .body-bookid .col-md-9 .col-contentes {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.bookid-lesson .body-bookid .padding-md{
		padding-left: 15px !important;
		padding-right: 15px !important;
	
	}
	.contentimg .cke_widget_drag_handler{
		display: none !important;
	}
	.contentimg img{
		width: 100% !important ;
	}
	.button-greedy{
		padding : 10px ;
	}
      #sidebar{
	    top: inherit !important;
		position: inherit !important;
		width:  inherit !important;
	  }
	.box_category_top .top p:nth-child(1) {
		font-size: 18px !important;
	}
	.box_category_top .top p {
		font-size: 14px;
	}
	.box_category_top .top{
		width: 55%;
	}
	.content-dimension img{
		width: 100% !important;
		height: auto !important;
	}

// exam //
.row-results-test{
   h3{
	   font-size: 20px;
   }
   h2{
	   font-size: 18px;
   }
  .col-img {
     .col-md-3:hover .overlay-rulest {
		height: 50%;
	  }
	  .text-center p {
		font-size: 15px;
		line-height: 18px;
	  }
	  .col-md-3{
		margin-top: 15px;
		text-align: center;
		img{
			width: 70%;
		}
		.col-hover{
			margin-top: 5px;
		}
	  }
   }
   .col-sub{
	 clear: both;
	 padding: 20px 27px;
	 .sub{
		padding-left: 0px;
		padding-right: 0px;
		a{
			padding: 7px 7px;
			font-size: 13px !important;
		}
	 }
   }
 }
 .row-exam-other .col-md-5 .test-orther{
	 display: block;
	 color: #ff7600;
	 font-weight: bold;
	 font-size: 18px;
	 padding-top: 20px;
 }
 .header-row-exam .col-md-4 p {
	 display: none;
 }
 .header-row-exam .col-md-6 {
 	padding-left: 0px;
  }

  /// sach id mobile 
   .body-bookid {
	   .col-md-3{
		   padding-left: 0px;
		   padding-right: 0px ;
	   }
	   .col-list{
			padding-left: 0px;
			padding-right: 0px ;
			.col-md-7{
				padding-left: 0px;
		     	padding-right: 0px ;
			}
	   }
	   .col-table{
			padding-left: 0px;
			padding-right: 0px ;
	   }
	   .list-video-mb{
		.left{
			padding-left: 15px;
		}
		span{
			width: 50% ;
			padding-top: 5px;
		}
	}
   }
   .profile-private section {
     	padding-top: 0px !important;
   }
   .panel-bookid-list{
		margin-top: 26px;
		.col-md-12{
			padding-left: 0px;
			padding-right: 0px ;
			.col-md-7{
				padding-left: 0px;
				padding-right: 0px ;
				.search{
					margin-top: 30px;
					display: flex;
					padding-left: 0px;
					padding-right: 0px ;
					.col-md-9{
						padding-left: 0px;
						padding-right: 0px ;
					}
					.col-md-2{
						padding-left: 0px;
						padding-right: 0px ;
					}
				}
			}
		}
		
		.panel-heading{
			padding-bottom: 0px !important;
		}
   }
   .profile-private{
    	padding-left: 0px;
    	padding-right: 0px ;
   }

}

/// sach id //////
.panel-money{
	.col-md-12 .col-md-5{
		padding-left: 0px;
	}
	padding-bottom: 20px;
	.search{
		margin-top: -5px;
		.form-control{
			height: 41px ;
		}
	}
}
.body-bookid .col-list .col-md-5{
	padding-left: 0px;
	padding-right: 0px;
}
.body-bookid .col-list .col-md-7 a{
	color: #1786be;
	font-weight: bold;
	font-size: 17px;
	padding-bottom: 10px ;
}
.body-bookid .col-list .col-md-7 p{
	margin: 0 0 0px ;
}
.body-bookid .col-list .col-md-7 .show{
	margin-top: 10px ;
}
.body-bookid{
  .col-content{
	    &>div{
			box-shadow: 0 0 15px rgba(0,0,0,0.15);
			padding: 15px;
			margin-bottom: 20px;
		}
		.file{
			margin-bottom: 15px;
		}
  }
}

.bookid-lesson{
	padding-left: 0px;
	padding-right: 0px;
	.body-bookid .col-md-9{
		padding-left: 0px;
		padding-right: 0px;
		.col-contentes{
			padding-left: 15px;
			padding-right: 7px;
		}
		.panel-heading h4{
			font-weight: bold;
		}
	}
	.wrap_main section {
		padding-top: 0px;
		margin-top: -20px;
	  }
	
	  .col-video {
		margin-bottom: 15px;
	  }
	
	  .list-video {
		display: none;
	  }
	
	  .col-video img {
		border: 1px solid #ccc;
		margin-bottom: 12px;
	  }
	
	  .col-video a {
		font-size: 16px;
		color: #333;
		font-weight: 600;
		line-height: 1;
	  }
	
	  .col-video a:hover {
		color: #1786be;
	  }
	
	  .body-bookid .col-md-3 p {
		font-weight: bold;
		font-size: 16px;
		color: #1786be;
	  }
	
	  #videoTitle {
		font-size: 16px;
		font-weight: bold;
		margin-top: 5px;
	  }
}

// menu kích hoạt sách id 
.bookid-menu {
	font-size: 13px;
	font-weight: bold;
	text-align: center;
	padding-bottom: 10px;
}

.bookid-menu .test {
	background: #f37435;
	color: #fff;
	padding: 5px 12px;
}

@-webkit-keyframes my {
	0% {
		color: #1786be;
	}

	50% {
		color: #fff;
	}

	100% {
		color: #1786be;
	}
}

@-moz-keyframes my {
	0% {
		color: #1786be;
	}

	50% {
		color: #fff;
	}

	100% {
		color: #1786be;
	}
}

@-o-keyframes my {
	0% {
		color: #1786be;
	}

	50% {
		color: #fff;
	}

	100% {
		color: #1786be;
	}
}

@keyframes my {
	0% {
		color: #1786be;
	}

	50% {
		color: #fff;
	}

	100% {
		color: #1786be;
	}
}

.test {
	background: #3d3d3d;
	font-weight: bold;
	-webkit-animation: my 700ms infinite;
	-moz-animation: my 700ms infinite;
	-o-animation: my 700ms infinite;
	animation: my 700ms infinite;
}

/// kết quả bài test //
	#sidebar {
		height: auto;
		margin-bottom: 20px ;
		.col-img{
		     &>div{
				    margin-top: -28px;
				 &>div{
					text-align: center;
				 }
				 p{
					 color: #ff9900;
				 }
			 }
			a{
				position: relative;
				color: #fff;
				color: #31b0d5;
				background: #fff;
				padding: 5px 10px;
				border-radius: 15px;
				font-weight: bold;
				&:hover{
					background: #31b0d5;
					color: #fff ;
				}
			}
		}
	}
	.row-exam-mb .col-md-9{
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
	}
	.row-exam-mb .col-md-3 .show{
		padding-left: 0px;
		padding-right: 0px;
		margin-bottom: 10px ;
	}
	.row-exam-mb .col-md-3 .show-end{
         padding-bottom: 28px;
	}
	.row-exam-mb .col-md-3 .show .col-md-4{
		padding-left: 0px;
		padding-right: 0px;
	}
	.row-exam-mb .col-md-3 .show .col-md-8,.col-md-4{ 
		/*padding-right: 0px;*/
	}
	.row-exam-mb .col-md-3 .show .col-md-8 a{     
		color: #5E7C7B ;
		font-weight: bold ;
	}
	.row-exam-mb .col-md-3>div p{
		font-weight: bold ;
		margin-top: 10px ;
		font-size: 18px;  
	} 
	.content-dimension  h3 b{
		font-weight: 900;
	}
// end kết quả bài test //




//profilepdf
#contentpdf {
    padding: 15px;
    transition: margin-left 0.7s;
    overflow: hidden;
    width: 100%;
    h1{
	    float: left;
	    margin-left: 60px;
	}
	.slide{
	    position: fixed;
	    float: left;
	    margin-right: 20px;
	    a {
	    	color: #ffffff;
		    font-size: 36px;
		    background: #f47935;
		    padding: 7px 10px;
	    }
	}

}

.navleftbar .close {
    position: absolute;
    top: 8px;
    right: 22px;
    margin-left: 50px;
    font-size: 30px;
    color: #ccc;
}
.navleftbar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    background-color: #248842;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.7s;
}
.navleftbar a.item {
    display: flex;
    line-height: 20px;
    flex-direction: row;
    padding: 8px 20px;
    font-size: 18px;
    text-decoration: none;
    color: #fff;
    background-color: #ff8d00;
    border-top: 1px solid #ccc;
}
.navleftbar .last {
    border-bottom: 1px solid #ccc;
}
.navleftbar a.item:hover {
    color: #fff;
    background-color: #cf0000;
    transition: 0.4s;
}
.navleftbar .icon {
    padding-right: 10px;
    font-size: 35px;
}
.navleftbar ul {
    display: flex;
    position: absolute;
    width: 100%;
    margin-top: 13px;
    text-align: center;
    padding-left: 10px;
}
.navleftbar ul li {
    list-style: none;
}
.navleftbar ul li a.inline {
    font-size: 26px;
    color: #fff;
    padding: 6px 5px 3px;
}
.navleftbar ul li a.inline:hover {
    color: #cf0000;
}



.add-more a{
	border-radius: 8px;
    padding: 4px 10px;
    color: #254f5b;
    border: 1px solid #254f5b;
    font-family: ExtraBoldItalic;
    margin-left: 15px;
    font-size: 12px;
}
.vn-pay-return{
	 .col-md-offset-3{
		text-align: center;
		border: 2px solid #9FC162;
		padding: 20px 0px;
		margin-top: 52px;
		h3{
			font-weight: bold;
		}
		p{
			margin-top: 10px;
			font-size: 17px;
		}
		.glyphicon-ok{
			font-size: 75px;
			color: #9FC162;
		}
		.glyphicon-remove{
			font-size: 75px;
			color: red;
		}
	 }
}

// sách id //


// kết quả bài test 

@media only screen and (max-width : 320px) {
	.row-results-test .col-sub .sub a {
		padding: 7px 2px;
	}
    .show-result form .btn-primary{
		padding: 10px 0px !important;
	  }
	  #body .body_content .left .start button {
		 padding: 5px 6px !important;
	  }
}


.info_header_test_mbti{
	background: url("../img/novai/bg-mbti-1.png") no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	@media only screen and (max-width : 680px) {
		background: none;
	}
	.container-header{
		padding: 60px 140px;
		@media only screen and (max-width : 680px) {
			padding: 10px 20px;
		}
		.company_name{
			line-height: 56px;
			font-weight: bold;
			text-align: right;
			@media only screen and (max-width : 680px) {
				line-height: normal;
				text-align: justify;
				padding-top: 10px;
			}
		}
	}
	h1{
		text-align: center;
		font-weight: 700;
		padding:25px 0;
	}
	.about-test p{
		font-size: 17px;
	}
}
.info_header_test_holland{
	background: url("../img/novai/bg-mbti-1.png") no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	@media only screen and (max-width : 680px) {
		background: none;
	}
	.container-header{
		padding: 60px 140px;
		@media only screen and (max-width : 680px) {
			padding: 10px 20px;
		}
		.company_name{
			line-height: 56px;
			font-weight: bold;
			text-align: right;
			@media only screen and (max-width : 680px) {
				line-height: normal;
				text-align: justify;
				padding-top: 10px;
			}
		}
	}
	h1{
		text-align: center;
		font-weight: 700;
		padding:25px 0;
	}
	.about-test p{
		font-size: 17px;
	}
}
.info_result_test_holland {
	background-position: center top;
	background-size: 100% auto;
	background-size: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 565px;
	@media only screen and (max-width : 680px) {
		height: 500px;
	}
}
.info_result_test_holland {
	background-image: url("../img/novai/bg-mtil.png");
	background-position: center top;
	background-size: 100% auto;
	background-size: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	//height: 580px;
	//@media only screen and (max-width : 680px) {
	//	height: 334px;
	//}
	.text_test_info{
		font-weight: bold;
		color: #676767;
		font-size: 15px !important;
		@media only screen and (max-width : 680px) {
			font-size: 13px !important;
		}
	}
}
.info_result_test_mbti {
	background-position: center top;
	background-size: 100% auto;
	background-size: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 565px;
	@media only screen and (max-width : 680px) {
		height: 500px;
	}
}
.info_result_test_mtil {
	background-image: url("../img/novai/bg-mtil.png");
	background-position: center top;
	background-size: 100% auto;
	background-size: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	//height: 580px;
	//@media only screen and (max-width : 680px) {
	//	height: 334px;
	//}
	.text_test_info{
		font-weight: bold;
		color: #676767;
		font-size: 15px !important;
		@media only screen and (max-width : 680px) {
			font-size: 13px !important;
		}
	}
}
.info_box{
	.text_name{
		font-weight: bold;
		color: #b11f2b;
		font-size: 35px;
		padding-top: 120px;
		@media only screen and (max-width : 680px) {
			padding-top: 90px;
		}
	}
	.congratulations_text{
		color: #fef2f6;
		text-align: center;
		font-size: calc(1em + 4vw);
		font-weight: 900;
		text-shadow: -0.0075em 0.0075em 0 #fef2f6, 0.005em 0.005em 0 #f6a5c1, 0.01em 0.01em 0 #f7aac4, 0.015em 0.015em #f7aec7, 0.02em 0.02em 0 #f8b3ca, 0.025em 0.025em 0 #f8b7cd, 0.03em 0.03em 0 #f8bcd0, 0.035em 0.035em 0 #f9c0d3;
		@media only screen and (max-width : 680px) {
			padding: 10px 0 5px;
			font-size: 35px;
		}
	}
}


.info_box_mtil{
	.congratulations_text{
		color: #fef2f6;
		text-align: center;
		font-size: calc(1em + 4vw);
		font-weight: 900;
		text-shadow: -0.0075em 0.0075em 0 #fef2f6, 0.005em 0.005em 0 #f6a5c1, 0.01em 0.01em 0 #f7aac4, 0.015em 0.015em #f7aec7, 0.02em 0.02em 0 #f8b3ca, 0.025em 0.025em 0 #f8b7cd, 0.03em 0.03em 0 #f8bcd0, 0.035em 0.035em 0 #f9c0d3;
		@media only screen and (max-width : 680px) {
			padding: 10px 0 5px;
			font-size: 35px;
		}
	}
	.text_name{
		font-weight: bold;
		color: #b11f2b;
		font-size: 35px;
	}
	.text_test_info{
		font-weight: bold;
		color: #676767;
		font-size: 25px;
		@media only screen and (max-width : 680px) {
			font-size: 18px;
		}
	}
    .text_test_result{
      	font-weight: bold;
      	text-transform: uppercase;
      	color: #000;
		font-size:26px;
		@media only screen and (max-width : 680px) {
			font-size: 14px;
		}
    }
}

.photos .blog_content{
	padding: 0px 12px;
	height: 80px;
	overflow: hidden;
	box-shadow: 0 0 15px rgba(0,0,0,0.15);
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
  }
  .photos .learn-img img{
	border-top-left-radius: 15px;                 
	border-top-right-radius: 15px;
  }
  .photos .learn-img{
	position: relative;
	overflow: hidden;
	height: 200px;
	background: #fff;
  }
  .photos{
	margin: 20px 0px;     
  }
  .photos .blog_title{
	 text-align: center ;
  }
  .photos .blog_content .blog_title{
	font-weight: bold;
	padding-top: 5px;
	
  }
  .photos .blog_content .blog_title a{
	color: #333;
  }
  .photos .blog_content .blog_title a:hover{
	 color: #2196F3;
  }

.gallery_item .image_link img {
	width: 100%;
	height: auto !important;    
}
.gallery_item .image_link .img {
	height: 85px;
}
.gallery_item .ripple {
	margin-top: -140px !important;
}
.gallery_item .image_link div {
	height: 100px !important;
}
