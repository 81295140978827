.breadcrumb-parter{
	color: #fff;

	h4{
		font-size: 20px;
	}
	small{
		font-size: 15px;
	}
	
	min-height: 150px;
	

	@media only screen and (min-width : 320px) {
		.text-welcome{
			padding:20px 0px;
		}
	}
	@media only screen and (min-width : 768px) {
		.text-welcome{
			padding:44px 0px;
		}
	}
	
}

.main-info-partner{
	background:#fff;
	
	.partner-info-left{
		h2{
			font-size: 15px;
			text-transform: uppercase;
			font-weight: bold;
			color:#333;
			margin: 0px 10px 20px 10px;
		}

		.info-diskcount{
			margin:0px 10px;
			margin-bottom: 50px;
			.diskcount{
				display: table;
				width:80px;
				height:80px;
				margin-right: 10px;
				strong{
					background: #d6c0a4;
    				border: 4px solid #eeeae5;
    				border-radius: 50%;
    				display: table-cell;
    				vertical-align: middle;
    				text-align: center;
    				color:#fff;
    				font-size: 30px;
    				font-weight: normal;
    				small{
    					font-size: 50%;
    				}
				}
			}

			.media-body{
				p{
					margin-top: 10px;
				}
			}

			.btn-partner-register{
				width:200px;
				margin-top: 30px;
			}
		}

		.panel-partner{
			border:1px solid #e2d4b7;
			box-shadow: none;
    		margin-bottom: 15px;
			.panel-heading{
				background:#fff9ee;
				border:none;
				.panel-title{
					font-size: 15px;
				    font-weight: bold;
				    color: #000;
				    text-transform: uppercase;
				}

				.icon-info{
					color: $brand-primary;
					margin-right: 5px;
				}
			}
		}
	}

	.partner-info-right{
		a{
			display:block;
			img{
				margin: 0 auto;
			}
		}
	}
	padding-top: 50px;
	padding-bottom: 50px;
}

.info_register{
	li{
		margin-bottom: 20px;
		.send_info{
			margin-top: 30px;
		}

		.info-website{
			margin-top: 10px;
			margin-left: 20px;
			input{
				height:35px;
				margin-bottom: 15px;
				max-width: 270px;
			}
		}
		.option-channel{
			height:40px;
			max-width: 270px;
		}
	}
}

.partner-done{
	.media-left{
		.done{
			width:55px;
			height:67px;
			display:inline-block;
		}
	}
	.media-body{
		p{
			margin:0;
			a{
				margin-top: 10px;
				display:block;
			}
		}
	}
}