.blog-list{
	border-radius: 0;

	@media only screen and (min-width : 768px) {
		margin-top: 30px;
	}

	.box_title{
		position: relative;
		width: 100%;
		align-items: center;
		display: flex;

		.title{
			font-weight: bold;
			text-transform: uppercase;
			color: #285558;
			padding-right: 15px;
			font-size: 25px;
		}

		b{
			height: 1px;
			opacity: 1;
			background-color: #2d4e55;
			display: block;
			flex: 1;
		}

		.btn_more{
			border-radius: 8px;
			padding: 5px 10px;
			color: #254f5b;
			border: 1px solid #254f5b;
			font-family: ExtraBoldItalic;
			margin-left: 15px;
			font-size: 15px;
		}

		&:before{

		}
	}

	.media{
		padding-bottom: 15px;

		.media-left{

			@media only screen and (min-width : 320px) {
				display: block;
				padding-right: 0;
				a{
					width:100%;
					height: auto;
					img{
						width: 100%;
						height: auto;
					}
				}
			}

			@media only screen and (min-width : 480px) {
				display: table-cell;
				margin-bottom: 0px;
				padding-right: 10px;
				a{
					width:180px;
					img{
						width: 100%;
					}
				}
			}

			a{
				display:block;
				margin-right: 10px;
			}

		}

		.media-md{

			@media only screen and (min-width : 320px) {
				margin-bottom: 10px;
			}

			@media only screen and (min-width : 480px) {
				a{
					width:300px;
				}
			}
		}

		.media-sm{
			@media only screen and (min-width : 480px) {
				a{
					width: 250px;
				}
			}
		}

		.media-xs{
			a{
				width:65px;
				@media only screen and (min-width : 320px) {
					float: left;
				}
				@media only screen and (min-width : 768px) {
					float: none;
				}
				img{
					width: 100%;
				}
			}
		}

		.media-body{

			.main-title{
				color: #32535a;
				font-weight: bold;
				text-transform: uppercase;
				font-size: 20px;
			}

			.media-heading{
				color: #32535a;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			a{
				font-size: 14px;
				color:#285558;
				font-weight: bold;

				&:hover,
				&:active{
					color:$brand-primary;
				}
			}

			a.list_small{
				color: #254f5b;
				font-weight: bold;
				font-size: 16px;
				text-transform: none;
			}

			p{
				line-height: 21px;
			}

			.btn_more{
				border-radius: 8px;
				padding: 4px 10px;
				color: #254f5b;
				border: 1px solid #254f5b;
				font-family: ExtraBoldItalic;
				margin-left: 15px;
				font-size: 12px;
			}
		}
	}
}

.list-meta{
	span{
		color: #32535a;
		font-size: 12px;
		margin-right: 10px;
		i{
			color: #f37435;
		}
	}
}

.blog-wrap-content{
	h1{
		font-size: 25px;
		margin: 15px 0;
		color: #254f5b;
	}
	.blog-summary{
		p{
			font-weight: bold;
		}
	}
	.content{
		img{
			max-width: 100% !important;
			height: auto;
		}
	}
}

.blog_index{
	@media only screen and (min-width : 320px) {
		margin-top: 30px;
	}
	@media only screen and (min-width : 768px) {
		margin-top: 0;
	}
}

.blog-detail{
	margin-top: 30px;
}

.comment-blog{
	background: #fff;
	margin-bottom: 10px;
}

.title-more{
	.title_blog_more{
	    color: #3f3f3f;
	    font-size: 18px;
	    padding-top: 20px;
	}
	.title_more{
		padding-bottom: 10px;
	}
	.line_small {
		border-top: #f37435 solid 2px;
    	margin-right: 600px;
	}
	.line_long {
		border-top: #ccc solid 1px;
	}
}

.blog-new{
	background:#fff;
	margin-bottom: 10px;
	padding:0 !important;
}

@media only screen and (min-width : 320px) {
	.blog-new .media{
		padding:0;
	}
}

@media only screen and (min-width : 480px) {
	.blog-hot li a{
		height:250px;
	}
}

@media only screen and (min-width : 768px) {
	.blog-hot li a{
		height:150px;
	}
	.blog-new{
		.media:first-child{
			margin-top: 15px;
		}
		.media{
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.media-list-view{
		float: left;
	    width: 50%;
	    position: relative;
	    padding: 0px 15px;
	}
	.media-list-view:first-child{
		margin-top: 15px;
	}
}

@media only screen and (min-width : 992px) {
	.media-list-view{
		float: none;
	    width: auto;
	    position: inherit;
	    padding: 0px;
	}
	.media-list-view:first-child{
		margin-top: 0px;
	}
}

/************************/
.dataTables_info{
   font-size: 12px;
   color:#999;
}
.create_blog{

   .control-label-error{
      font-size: 12px;
      margin-top: 5px;
      margin-bottom: 0;
      color:#f4645f
   }
}

